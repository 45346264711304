import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

class YearPredictionHistoryBarchart extends React.Component {
    static propTypes = {
        months: PropTypes.arrayOf(PropTypes.shape({
                date: PropTypes.string.isRequired,
                predictionLevel: PropTypes.number.isRequired,
            }))
            .isRequired,
        className: PropTypes.string
    };

    componentDidMount = () => {
        const barchartYear = document.querySelector('.js-barchart-year');
        barchartYear.scrollLeft += barchartYear.scrollWidth;
    };

    render() {
        const { months, className } = this.props;
        const classes = classnames('barchart js-barchart-year', className);

        return (
            <div className={classes}>
                {months.map((month) => (
                    <div key={month.date} data-prediction={month.predictionLevel} className="barchart__bar">
                        <div className="barchart__bar-fill-wrap">
                            <div className="barchart__bar-fill">
                                <span className="barchart__bar-number">{month.burglaries > 0 ? month.burglaries : ''}</span>
                            </div>
                        </div>
                        <span className="barchart__label">{moment(month.date).format('MMM').replace('.', '')}</span>
                        <span className="barchart__label">{month.date.includes('01-01') ? moment(month.date).format('YYYY') : ''}</span>
                    </div>
                ))}
            </div>
        );
    }
}

export default YearPredictionHistoryBarchart;
