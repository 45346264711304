const currentEnvironment = process.env.NODE_ENV || 'production';

export function pushToDataLayer(item, dataLayerName = 'dataLayer') {
    window[dataLayerName].push(item);

    if (currentEnvironment === 'dev') {
        console.log(item);
    }
}

export default pushToDataLayer;
