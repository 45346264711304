import React from 'react';
import { withRouter } from 'react-router-dom';
import { getProfile } from '../core-helpers/content';
import pushToDataLayer from '../core-helpers/gtm';

// Content
import content from '../content';

// Components
import CallToAction from '../core-components/call-to-action';
import PropTypes from 'prop-types';
import {NOT_FOUND as HTTP_NOT_FOUND, OK as HTTP_OK} from "http-status-codes";
import {getStore} from '../store';

// Constants
const POSTAL_CODE_MAX_LENGTH = 4;

class PredictCTA extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        const profile = getProfile();

        if (profile.postalCode && !location.hash) {
            window.location.hash = '#' + profile.postalCode.substr(0, POSTAL_CODE_MAX_LENGTH);
        }

        this.state = {
            postalCodeInput: this.getPostalCodeFromURL(),
            error: false,
            tooltipText: ''
        };
    }

    getPostalCodeFromURL() {
        return window.location.hash.replace(/^#/, '');
    }

    handleChangePostalCode = (e) => {
        this.setState({
            postalCodeInput: e.target.value.replace(/[^\d]/, '').substr(0, POSTAL_CODE_MAX_LENGTH),
            error: false
        });
    };

    isPostalCodeValid = () => {
        const { postalCodeInput } = this.state;

        return /^[1-9][0-9]{3}/.test(postalCodeInput);
    };

    canSubmit = () => {
        return this.isPostalCodeValid();
    };

    pushActionToDataLayer = (action, postalCode) => {
        pushToDataLayer({
            event: 'BurglaryToolAction',
            burglaryAction: action,
            burglaryPostalCode: postalCode
        });
    };

    handleSubmit = async(e) => {
        e.preventDefault();

        if (!this.canSubmit()) {
            return;
        }
        const { BASENAME } = getStore();
        const {postalCodeInput} = this.state;
        const { history } = this.props;

        const response = await fetch(`${BASENAME}/api/postcode/${postalCodeInput}`);
        const headerForm = document.querySelector('.js-header-postal-code');
        const headerInput = headerForm.querySelector('.header-postal-code__input');

        switch ( response.status ) {
            case HTTP_OK: {
                response.json()
                        .then(() => {
                            pushToDataLayer({
                                event: 'BurglaryToolAction',
                                burglaryAction: 'BekijkRisicoFooter',
                                burglaryPostalCode: postalCodeInput.toString()
                            });
                        })
                        .finally(() => {
                            headerForm.classList.remove('has-error');
                            headerInput.value = '';
                            this.props.history.push(`/resultaat#${postalCodeInput}`);
                        });

                return;
            }
            case HTTP_NOT_FOUND: {
                const json = await response.json();

                this.setState({
                    error: true,
                    tooltipText: json.error
                });
                pushToDataLayer({
                    event: 'BurglaryToolAction',
                    burglaryAction: 'BekijkRisicoFooterError',
                    burglaryPostalCode: postalCodeInput.toString()
                });

                return;
            }
            default: {
                return alert('Er ging iets mis tijdens het ophalen van de data, probeer het later nog eens'); // eslint-disable-line no-alert
            }
        }


    };

    render() {
        const { postalCodeInput, error, tooltipText } = this.state;

        return (
            <CallToAction className="cta--predict cta--flatten">
                <h2>{content.cta.predict.title}</h2>
                <form className={`prediction-form prediction-form--light ${error ? 'has-error' : ''}`} onSubmit={this.handleSubmit}>
                    <input type="number" pattern="^[0-9]" min="0" value={postalCodeInput} placeholder={content.homeArticle.form.placeholder} onChange={this.handleChangePostalCode} />
                    <button type='submit' className={this.canSubmit() ? 'button' : 'button button--disabled'} disabled={!this.canSubmit()}>{content.homeArticle.form.button}</button>
                    {
                        error && <div className="prediction-form__tooltip">
                            <span className="prediction-form__tooltip__text">{tooltipText}</span>
                        </div>
                    }
                </form>
                <p>{content.cta.predict.action.tooltip}</p>
            </CallToAction>
        );
    }
}

export default withRouter(PredictCTA);
