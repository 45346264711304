import React from 'react';
import PropTypes from 'prop-types';

function LockToolFooter(props) {
    return (
        <div className="locktool__footer">
            <div className="container">
                <div className="locktool__footer-buttons">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

LockToolFooter.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired
};

export default LockToolFooter;
