import React from 'react';
import PropTypes from 'prop-types';
import pushToDataLayer from '../core-helpers/gtm';

// Icons
import { ReactComponent as ClockIconSVG } from '../../../images/sprites/clock.svg';
import { ReactComponent as ShieldIconSVG } from '../../../images/sprites/shield.svg';
import { ReactComponent as ChevronRightIconSVG } from '../../../images/sprites/chevron-right.svg';

class PreventionTip extends React.Component {

    static propTypes = {
        preventionTip: PropTypes.shape({
            id: PropTypes.number.isRequired,
            thumbnail: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            effectiveness: PropTypes.number.isRequired,
            effort: PropTypes.number.isRequired,
            priceDiscount: PropTypes.number,
            realPrice: PropTypes.number,
            imageAlt: PropTypes.string,
            explanation: PropTypes.string.isRequired,
        }).isRequired,
        postalCode: PropTypes.string,
        prediction: PropTypes.string,
        effortFilter: PropTypes.number,
        priceFilter: PropTypes.number,
        propertyTypeFilter: PropTypes.string
    };

    pushActionToDataLayer = (action, tip) => {
        const { postalCode, prediction, effortFilter, priceFilter, propertyTypeFilter } = this.props;

        pushToDataLayer({
            event: 'BurglaryToolAction',
            burglaryAction: action,
            burglaryPreventionTip: tip,
            burglaryPostalCode: postalCode,
            burglaryPredictionLevel: prediction,
            effortFilter: effortFilter,
            priceFilter: priceFilter,
            propertyTypeFilter: propertyTypeFilter
        });
    };

    createRating(number, icon) {
        const maxScore = 4;
        const rating = [];
        for (let i = 0; i < maxScore; i++) {
            const starClass = i < number ? 'prevention-tip-card__icon--filled' : '';
            rating.push(
                icon === 'shield' ?
                <ShieldIconSVG key={`svg:${i}`} className={`prevention-tip-card__icon ${starClass}`} />
                : <ClockIconSVG key={`svg:${i}`} className={`prevention-tip-card__icon ${starClass}`} />
                );
        }

        return rating;
    };

    showPrice(priceDiscount, realPrice) {
        if (priceDiscount > 0) {
            return `€ ${priceDiscount},-`;
        } else if (realPrice > 0) {
            return `€ ${realPrice},-`;
        }

        return '-';
    };

    render() {
        const { preventionTip } = this.props;

        if (preventionTip) {
            return (
                <a href={'/preventietips/' + preventionTip.slug} className="prevention-tip-card" onClick={this.pushActionToDataLayer.bind(this, 'GoToPreventionTipDetail', preventionTip.title)}>
                    <header className="prevention-tip-card__header">
                        <img src={preventionTip.thumbnail} alt={preventionTip.imageAlt} />
                    </header>
                    <div className="prevention-tip-card__content-wrapper">
                        <div className="prevention-tip-card__content">
                            <div className="prevention-tip-card__stats-bar">
                                <div className="prevention-tip-card__icon-holder tooltip-trigger">
                                    {this.createRating(preventionTip.effectiveness, 'shield')}
                                    <div className="tooltip">
                                        <span className="tooltip__text">Deze score laat zien in welke mate het bijdraagt aan een veiliger huis.</span>
                                    </div>
                                </div>
                                <span className="prevention-tip-card__icon-splitter">|</span>
                                <div className="prevention-tip-card__icon-holder tooltip-trigger">
                                    {this.createRating(preventionTip.effort, 'clock')}
                                    <div className="tooltip">
                                        <span className="tooltip__text">Deze score laat zien hoeveel inspanning het uitvoeren van deze tip jou kost.</span>
                                    </div>
                                </div>
                                <div className="prevention-tip-card__price">
                                    {this.showPrice(preventionTip.priceDiscount, preventionTip.realPrice)}
                                </div>
                            </div>
                            <h3 className="prevention-tip-card__heading">{preventionTip.title}</h3>
                            <div className="prevention-tip-card__explanation">{preventionTip.explanation}</div>
                        </div>
                        <footer className="prevention-tip-card__footer">
                            <button className="button button--outline button--small prevention-tip-card__button">
                                Bekijk de tip
                                <ChevronRightIconSVG className="button__icon"/>
                            </button>
                        </footer>
                    </div>
                </a>
            );
        }
        return null;
    }
}

export default PreventionTip;
