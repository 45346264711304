import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ShieldIconSVG } from '../../../../images/sprites/shield.svg';
import { ReactComponent as ChevronRightIconSVG } from '../../../../images/sprites/chevron-right.svg';

function LockTip(props) {
    const { score, title, image, imageAlt, children, link, linkText } = props;
    return (
        <div className="locktip">
            {image && (
                <header className="locktip__header">
                    <img src={image} alt={imageAlt} />
                </header>
            )}
            <div className="locktip__content-wrapper">
                <h2>{title}</h2>
                <div className="locktip__score">
                    <ShieldIconSVG /> <span>+{score}</span> veiligheidsscore
                </div>
                <div className="locktip__content">{children}</div>
                {link && (
                    <a className="locktip__button" href={link} target="_blank" rel="noopener noreferrer" >
                        {linkText} <ChevronRightIconSVG />
                    </a>
                )}
            </div>
        </div>
    );
}

LockTip.propTypes = {
    score: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    link: PropTypes.string,
    linkText: PropTypes.string,
    image: PropTypes.string,
    imageAlt: PropTypes.string,
};

export default LockTip;
