import { getStore } from '../store';
import { isSlimThuisWebview } from './content';

const getPrefix = () => {
    const { IDENTIFIER } = getStore();

    return `tools:${IDENTIFIER.replace(/:/g, '_').replace(/[^A-Za-z0-9_]/g, '')}__`;
};

export const track = (type, event) => {
    if ( !type || !event || !isSlimThuisWebview() || !window.ToolApp || typeof window.ToolApp[`post${type}`] === 'undefined' ) {
        return;
    }

    const content = JSON.stringify(event);

    if ( !content ) {
        return;
    }

    return window.ToolApp[`post${type}`](content);
};

export const trackEvent = (event = {}) => {
    if ( typeof event === 'string' ) {
        event = {
            eventName: event
        };
    }

    track('Event', Object.assign({
        eventName: '',
        itemCategory: '',
        itemName: ''
    }, event, {
        eventName: `${getPrefix()}${event.eventName}`
    }));
};

export const trackRoute = (route) => {
    if ( !route ) {
        return;
    }

    track('Screen', {
        screenName: `${getPrefix()}${route}`.replace(/\//g, '')
    });
};
