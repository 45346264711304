import React, {useState, useEffect, useContext} from 'react';
import {withRouter} from 'react-router-dom';
import {ReactComponent as ShieldIconSVG} from '../../../../images/sprites/shield-tool.svg';
import {
    NOT_FOUND as HTTP_NOT_FOUND,
    BAD_REQUEST as HTTP_BAD_REQUEST,
    OK as HTTP_OK,
} from 'http-status-codes';
import {getStore} from '../../store';
import PreventionTip from '../../components/prevention-tip';
import LockTip from './lock-tip';
import {LockToolContext} from '../../context/lockToolContext';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import pushToDataLayer from '../../core-helpers/gtm';

const LEVEL = {
    BAD: {
        maxValue: 30,
        name: 'critical',
        text: 'niet veilig',
    },
    MEDIUM: {
        maxValue: 70,
        name: 'normal',
        text: 'veilig',
    },
    GOOD: {
        maxValue: 100,
        name: 'good',
        text: 'heel veilig',
    },
};

const LockToolResult = ({history, location}) => {
    const lockToolFormController = useContext(LockToolContext);
    const [isFetching, setIsFetching] = useState(true);
    const [preventionTips, setPreventionTips] = useState([]);
    const [level, setLevel] = useState(LEVEL.BAD);
    const [checkData, setCheckData] = useState({});
    const [advices, setAdvices] = useState([]);
    const params = new URLSearchParams(location.search);

    const checkLevel = (level) => {
        switch (true) {
            case level <= LEVEL.BAD.maxValue: {
                return LEVEL.BAD;
            }
            case level <= LEVEL.MEDIUM.maxValue: {
                return LEVEL.MEDIUM;
            }
            case level <= LEVEL.GOOD.maxValue: {
                return LEVEL.GOOD;
            }
            default: {
                return LEVEL.BAD;
            }
        }
    };

    useEffect(() => {
        async function asyncFetchResults(url) {
            await fetchResult(url);
        }

        const url = createUrl();
        asyncFetchResults(url);
    }, []);

    const checkFormComplete = () => {
        // checks if there is a query param for each of the steps
        for (const [key,] of Object.entries(
            lockToolFormController.formData
        )) {
            if (!params.get(key)) {
                return false
            }
        }
        return true;
    };

    const fetchResult = async (url) => {
        if (!checkFormComplete()) {
            history.push('./');
            return;
        }
        const response = await fetch(url);

        switch (response.status) {
            case HTTP_OK: {
                response.json().then((data) => {
                    setIsFetching(false);
                    setLevel(checkLevel(data.lockCombination.score100));
                    setCheckData(data.lockCombination);
                    setAdvices(data.advices);
                    pushActionToDataLayer(data);
                });
                return;
            }
            case HTTP_NOT_FOUND: {
                setIsFetching(false);
                return;
            }
            default: {
                setIsFetching(false);
                return alert(
                    'Er ging iets mis tijdens het ophalen van de data, probeer het later nog eens'
                ); // eslint-disable-line no-alert
            }
        }
    };

    const createUrl = () => {
        return `${window.location.origin}/api/lock/combination?type=${
            params.get('cylinderInstallationType')
        }&screwsOnShield=${!!parseInt(
            params.get('screwsOnShield')
        )}&cylinderInstallation=${
            params.get('cylinderInstallation')
        }&starsOnLock=${+params.get('starsOnLock')}&starsOnCylinder=${+params.get('starsOnCylinder')}`;
    };

    const renderLoader = () => {
        const classes = classnames('loader-container', {
            'is-loading': isFetching,
        });

        return (
            <div className={classes}>
                <div className="loader loader--large"></div>
            </div>
        );
    };

    const pushActionToDataLayer = (data) => {
        const datalayerObj = {
            event: 'LockToolAction',
            lockToolAction: 'result shown',
            lockLevelText: checkLevel(data.lockCombination.score100).text,
            lockLevelNumber: data.lockCombination.score100,
            ...(data.advices.length > 0 && {advices: data.advices}),
        };
        pushToDataLayer(datalayerObj);
    };

    return (
        <div className="locktool">
            {renderLoader()}
            <div className="locktool__content locktool__content--result">
                <div className="container">
                    <div className="locktool__header locktool__header--result">
                        <div
                            className={`locktool__result-summary locktool__result-summary--${level.name}`}
                        >
                            <div className="shield-container">
                                <ShieldIconSVG/>
                                <div className="shield-container__score">
                                    <div className="shield-container__score-dynamic">
                                        {checkData.score100}
                                    </div>
                                    /100
                                </div>
                            </div>

                            <h1 className="locktool__result-summary-title">
                                Jouw slot is <span>{level.text}</span> tegen
                                inbrekers
                            </h1>
                        </div>
                    </div>
                    <div className="locktool__result-content">
                        {!isFetching && advices.length > 0 ? (
                            <>
                                <h2>Zo maak je jouw slot {level === LEVEL.GOOD && (<>nog </>)}veiliger</h2>
                                <div className="locktips__holder">
                                    {advices.map((advice, index) => (
                                        <LockTip
                                            key={`advise:${index}`}
                                            image={
                                                advice.preventionTipThumbnailUrl
                                            }
                                            imageAlt={advice.title}
                                            title={advice.title}
                                            score={
                                                advice.improveScoreBy
                                            }
                                            link={
                                                advice.preventionTipUrl
                                            }
                                            linkText={advice.ctaText}
                                        >
                                            <p>{advice.description}</p>
                                        </LockTip>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <h2>We hebben geen tips om je slot te verbeteren</h2>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

LockToolResult.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
};

export default withRouter(LockToolResult);
