import React from 'react';
import { withRouter } from 'react-router-dom';
import { getProfile } from '../core-helpers/content';
import pushToDataLayer from '../core-helpers/gtm';

// Components
import PropTypes from 'prop-types';
import SignupForNotifications from "./signup-for-notifications";

// SVGs
import { ReactComponent as BellIconSVG } from '../../../images/sprites/bell.svg';
import notificationImage from '../../../images/notificatie-v2-min.png';

// Constants
const POSTAL_CODE_MAX_LENGTH = 4;

class BurglaryAlertCTA extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        const profile = getProfile();

        if (profile.postalCode && !location.hash) {
            window.location.hash = '#' + profile.postalCode.substr(0, POSTAL_CODE_MAX_LENGTH);
        }

        this.state = {
            showSignupForNotifications: false
        };
    }

    // Signup for notifications

    onClickShowSignupForNotifications = (e) => {
        e.preventDefault();

        this.pushActionToDataLayer('NotificatiesPopup');

        this.showSignupForNotifications();
    };

    signedUpForNotifications = () => {
        this.pushActionToDataLayer('NotificatiesAangemeld');
    };

    showSignupForNotifications = () => {
        this.setState({
            showSignupForNotifications: true
        });
    };

    hideSignupForNotifications = () => {
        this.setState({
            showSignupForNotifications: false
        });
    };

    renderSignupForNotificationsModal = () => {
        if (!this.state.showSignupForNotifications) {
            return null;
        }

        return (
            <SignupForNotifications onClose={this.hideSignupForNotifications} onSuccessCallback={this.signedUpForNotifications} signupData={{postalCode: this.state.postalCode}}/>
        );
    };

    pushActionToDataLayer = (action, postalCode) => {
        pushToDataLayer({
            event: 'BurglaryToolAction',
            burglaryAction: action,
            burglaryPostalCode: postalCode
        });
    };

    render() {
        return (
            <>
                {this.renderSignupForNotificationsModal()}
                <section className="section">
                    <div className="container">
                        <div className="burglary-alert">
                            <div className="burglary-alert__content">
                                <h2><BellIconSVG className="risk-alert-banner__icon" /> Inbraak-alert ontvangen?</h2>
                                <p>Al 5993 mensen krijgen een bericht wanneer het inbraakrisico in hun wijk hoger wordt. Meld je aan, zodat ook jij altijd op de hoogte blijft van het risico in jouw buurt.</p>
                                <button type="button" onClick={this.onClickShowSignupForNotifications} className="button button--small">Aanmelden voor inbraak-alert</button>
                            </div>
                            <div className="burglary-alert__image">
                                <img src={notificationImage} alt="Notificatie" />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default withRouter(BurglaryAlertCTA);
