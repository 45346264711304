import React from 'react';
import PropTypes from 'prop-types';

// SVGs
import { ReactComponent as QuestionMarkRoundIconSVG } from '../../../images/sprites/question-mark-round.svg';

export default class StatisticsListItem extends React.Component {

    static propTypes = {
        highlightedText: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        icon: PropTypes.node,
        label: PropTypes.string.isRequired,
        helpText: PropTypes.string
    };

    renderStatisticsHelp = () => {
        if (typeof this.props.helpText === 'undefined') {
            return;
        }

        return (
            <div className="statistics-list__help">
                <QuestionMarkRoundIconSVG className="statistics-list__help-icon" />
                <div className="statistics-list__tooltip">
                    <span className="statistics-list__tooltip-text">{this.props.helpText}</span>
                </div>
            </div>
        );
    };

    render() {
        const { highlightedText, icon, label } = this.props;

        return (
            <li className="statistics-list__item">
                <icon />
                <div className="statistics-list__content">
                    <span className="statistics-list__text">
                        {highlightedText === undefined || highlightedText === null ? '-' : highlightedText } {label}
                    </span>
                    {this.renderStatisticsHelp()}
                </div>
            </li>
        );
    }
}
