import React from 'react';
import { connect } from 'react-redux';
import InlineSVG from 'svg-inline-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import pushToDataLayer from '../core-helpers/gtm';
import {
    OK as HTTP_OK,
    BAD_REQUEST as HTTP_BAD_REQUEST
} from 'http-status-codes';

// Components
import SignupForNotifications from './signup-for-notifications';
import BurglaryAlertCTA from './burglary-alert-cta.jsx';
import Footer from './footer';
import StatisticsListItemB from './statistics-list-item-b';

// Redux
import { getStore } from '../store';

// Content
import content from '../content';

// SVGs
import OdometerSVG from 'svg-inline-loader!../../../odometer/odometer-b.svg';
import { ReactComponent as InfoIcon } from '../../../images/sprites/info-round.svg';
import { ReactComponent as ChevronRightIconSVG } from '../../../images/sprites/chevron-right.svg';

// Components
import HowItWorksModal from './how-it-works-modal';
import PreventionTip from "./prevention-tip";
import Helmet from 'react-helmet';

// Constants
const ODOMETER_ANIMATION_DURATION = 250;

class PredictionResultViewB extends React.Component {
    odometerRef = React.createRef();

    static propTypes = {
        location: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            postalCode: null,
            statistics: {},
            preventionTips: [],
            showHowItWorks: false,
            showSignupForNotifications: false,
            activeStepCTA: null,
            isFetching: false
        };
    }

    componentDidMount() {
        this.updatePostalCode();
        this.fetchPreventionTips();
    }

    componentDidUpdate(prevProps, prevState) {
        this.updatePostalCode();

        // TM-1294: Force a repaint on the odometer SVG in Internet Explorer once the animation finishes
        const ua = window.navigator.userAgent;
        if ( ua.includes('MSIE ') || ua.includes('Trident/') && prevState.statistics.postalCode !== this.state.statistics.postalCode ) {
            setTimeout(() => {
                this.odometerRef.current.style.opacity = 0.999;
                setTimeout(() => {
                    this.odometerRef.current.style.opacity = 1;
                }, ODOMETER_ANIMATION_DURATION);
            }, ODOMETER_ANIMATION_DURATION);
        }
    }

    fetchData = async() => {
        const { postalCode } = this.state;
        if ( !postalCode ) {
            return;
        }

        this.setState({
            isFetching: true,
            statistics: {}
        });

        const { BASENAME } = getStore();
        const response = await fetch(`${BASENAME}/api/voorspelling/${postalCode}`);

        this.setState({
            isFetching: false
        });

        switch ( response.status ) {
            case HTTP_OK: {
                const json = await response.json();
                pushToDataLayer({
                    event: 'BurglaryToolAction',
                    burglaryAction: 'StatistiekenGeladen',
                    burglaryPostalCode: json.postalCode,
                    burglaryPredictionLevel: json.prediction?.level ? json.prediction.level : undefined
                });
                return this.setStatistics(json);
            }
            case HTTP_BAD_REQUEST: {
                const json = await response.json();

                alert(json.error); // eslint-disable-line no-alert
                window.location.href = BASENAME;

                return;
            }
            default: {
                return alert('Er ging iets mis tijdens het ophalen van de data, probeer het later nog eens'); // eslint-disable-line no-alert
            }
        }
    };

    setStatistics = (statistics) => {
        this.setState({
            statistics
        });
    };

    fetchPreventionTips = async() => {
        this.setState({
            isFetching: true
        });

        const { BASENAME } = getStore();
        const response = await fetch(`${BASENAME}/api/preventietips`);

        this.setState({
            isFetching: false
        });

        switch ( response.status ) {
            case HTTP_OK: {
                const json = await response.json();
                return this.setPreventionTips(json);
            }
            case HTTP_BAD_REQUEST: {
                const json = await response.json();

                alert(json.error); // eslint-disable-line no-alert
                window.location.href = BASENAME;

                return;
            }
            default: {
                return alert('Er ging iets mis tijdens het ophalen van de data, probeer het later nog eens'); // eslint-disable-line no-alert
            }
        }
    };

    setPreventionTips = (preventionTips) => {
        this.setState({
            preventionTips: preventionTips.filter((preventionTip) => preventionTip.showOnResultPage)
        });
    };

    getPostalCodeFromURL() {
        return window.location.hash.replace(/^#/, '');
    }

    updatePostalCode() {
        const postalCode = this.getPostalCodeFromURL();

        if (postalCode !== this.state.postalCode) {
            this.setState({
                postalCode: postalCode
            }, this.fetchData);
        }
    }

    // Signup for notifications

    onClickShowSignupForNotifications = (e) => {
        e.preventDefault();

        this.pushActionToDataLayer('NotificatiesPopup');

        this.showSignupForNotifications();
    };

    signedUpForNotifications = () => {
        this.pushActionToDataLayer('NotificatiesAangemeld');
    };

    showSignupForNotifications = () => {
        this.setState({
            showSignupForNotifications: true
        });
    };

    hideSignupForNotifications = () => {
        this.setState({
            showSignupForNotifications: false
        });
    };

    // End Signup for notifications

    getPredictionLevel = () => {
        const { statistics } = this.state;
        return ((prediction) => {
            if ( !Object.values(statistics).length ) {
                return '';
            }

            if ( typeof prediction === 'undefined' ) {
                return 'unknown';
            }

            return statistics.prediction.level;
        })(statistics.prediction);
    };

    render() {
        const { preventionTips, postalCode, statistics } = this.state;
        const prediction = ((statistics.prediction && statistics.prediction.level) ? statistics.prediction.level : 0).toString();

        return (
            <React.Fragment>
                {this.renderHelmet()}
                {this.renderHowItWorksModal()}
                {this.renderSignupForNotificationsModal()}
                {this.renderIntro()}
                {this.renderPreventionTips(preventionTips, postalCode, prediction)}
                <BurglaryAlertCTA />
                <Footer flatten/>
            </React.Fragment>
        );
    }

    renderHelmet() {
        if (!this.state.statistics || Object.keys(this.state.statistics).length === 0 && this.state.statistics.constructor === Object) {
            return;
        }
        const { postalCode, statistics } = this.state;
        const { municipality, numberOfDaysSinceLastBurglary, prediction} = statistics;

        return (
            <Helmet>
                <title>{`Inbraakrisico ${postalCode} - Inbraakbarometer`}</title>
                <meta property="og:title" content={`Inbraakrisico ${postalCode} - Inbraakbarometer`} />
                <meta name="description" content={`Het inbraakrisico in ${postalCode} in ${municipality.name} is ${this._getPredictionText(prediction.level)}. De laatste inbraak was ${numberOfDaysSinceLastBurglary} dagen geleden. Ontvang een bericht wanneer het inbraakrisico jouw wijk hoger wordt.`} />
                <meta property="og:description" content={`Het inbraakrisico in ${postalCode} in ${municipality.name} is ${this._getPredictionText(prediction.level)}. De laatste inbraak was ${numberOfDaysSinceLastBurglary} dagen geleden. Ontvang een bericht wanneer het inbraakrisico jouw wijk hoger wordt.`} />
            </Helmet>
        )
    }

    renderPreventionTips(preventionTips, postalCode, prediction) {
        return (
            <section className="section">
                <div className="container">
                    <h2 className="prevention-tip-card-heading">Zo voorkom je inbraken, simpel en effectief</h2>
                    <div className="prevention-tip-card-holder">
                        {preventionTips.map((preventionTip) => (
                            <PreventionTip key={`id:${preventionTip.id}`} preventionTip={preventionTip} postalCode={postalCode} prediction={prediction}/>
                        ))}
                        <Link className="link--arrow gtm-all-tips" to={'/preventietips'}>Bekijk alle inbraakpreventie-tips <ChevronRightIconSVG /></Link>
                    </div>
                </div>
            </section>
        );
    }

    pushActionToDataLayer = (action) => {
        const { postalCode, statistics } = this.state;
        const prediction = ((statistics.prediction && statistics.prediction.level) ? statistics.prediction.level : 0).toString();

        pushToDataLayer({
            event: 'BurglaryToolAction',
            burglaryAction: action,
            burglaryPostalCode: postalCode,
            burglaryPredictionLevel: prediction
        });
    };

    pushManicipalityToDataLayer = (municipality) => {
        const { postalCode, statistics } = this.state;
        const prediction = ((statistics.prediction && statistics.prediction.level) ? statistics.prediction.level : 0).toString();
        pushToDataLayer({
            event: 'BurglaryToolAction',
            burglaryAction: 'BekijkGemeenteVanuitResultaat',
            burglaryManicipality: municipality,
            burglaryPostalCode: postalCode,
            burglaryPredictionLevel: prediction
        });
    };

    // How it works

    showHowItWorks() {
        this.setState({
            showHowItWorks: true
        });

        this.pushActionToDataLayer('HoeWerktHetPopup');
    }

    hideHowItWorks = () => {
        this.setState({
            showHowItWorks: false
        });
    };

    handleClickHowItWorks = (e) => {
        e.preventDefault();
        this.showHowItWorks();
    };

    renderHowItWorksModal = () => {
        if (!this.state.showHowItWorks) {
            return null;
        }

        return (
            <HowItWorksModal onClose={this.hideHowItWorks}/>
        );
    };

    // End how it works

    _getPredictionText = (predictionLevel) => {
        const predictionLevelTexts = {
            0: 'Onbekend',
            1: 'zeer laag',
            2: 'zeer laag',
            3: 'laag',
            4: 'laag',
            5: 'middelmatig',
            6: 'middelmatig',
            7: 'hoog',
            8: 'hoog',
            9: 'zeer hoog',
            10: 'zeer hoog',
        };

        if (predictionLevel === 0) {
            return predictionLevelTexts[predictionLevel];
        }

        return predictionLevelTexts[predictionLevel];
    };

    _createShareLink = (statistics, postalCode) => {
        if (!this._getPredictionText(this.getPredictionLevel()) && !statistics.municipality) {
            return `https://api.whatsapp.com/send?text=Ben jij je bewust van het inbraakrisico in jouw wijk? Bekijk https://inbraakbarometer.nl/?whatsapp-share`;
        }

        return `https://api.whatsapp.com/send?text=Ik heb een ${this._getPredictionText(this.getPredictionLevel())} inbraakrisico (postcode ${postalCode} in de gemeente ${statistics.municipality.name}). Ben jij je bewust van het inbraakrisico in jouw wijk? Bekijk https://inbraakbarometer.nl/?whatsapp-share`;
    };

    renderIntro() {
        const { statistics, postalCode } = this.state;

        return (
            <section className="section section--odometer">
                <div className="container">
                    <div className="odometer-b">
                        {this.renderIntroOdometer()}
                        <div className="odometer-b__meta">
                            <h1>
                                {content.statistics.main.title}
                                <Link to={'/'} className="link-color" onClick={this.pushActionToDataLayer.bind(this, 'PostcodeWijzigen')}> {postalCode}</Link>
                                {this.renderMunicipality(statistics)}
                            </h1>
                            <button className="how-it-works-link" onClick={this.handleClickHowItWorks}><InfoIcon className="how-it-works-link__icon" /> {content.statistics.odometer.howItWorks}</button>
                            <ul className="statistics-list--b" data-prediction={this.getPredictionLevel()}>
                                <StatisticsListItemB
                                    highlightedText={!statistics.numberOfBurglaries ? '-' : statistics.numberOfBurglaries}
                                    label={content.statistics.main.blocks.total}
                                />
                                <StatisticsListItemB
                                    highlightedText={!statistics.numberOfBurglaryAttempts ? '-' : statistics.numberOfBurglaryAttempts}
                                    label={content.statistics.main.blocks.attempts}
                                />
                                <StatisticsListItemB
                                    highlightedText={!statistics.numberOfDaysSinceLastBurglary ? '-' : statistics.numberOfDaysSinceLastBurglary}
                                    label="dagen sinds de laatste inbraak"
                                    helpText="Hoe minder dagen geleden, hoe groter de kans op een nieuwe inbraak."
                                />
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    renderIntroOdometer() {
        const { postalCode, isFetching } = this.state;
        const classes = classnames('odometer', {
            'is-loading': isFetching
        });

        if ( postalCode ) {
            return (
                <button type="button" className="odometer-outer" onClick={this.handleClickHowItWorks} ref={this.odometerRef}>
                    <InlineSVG src={OdometerSVG} className={classes} data-prediction={this.getPredictionLevel()}/>
                    <span className="odometer-outer__tooltip">{content.statistics.odometer.tooltip}</span>
                </button>
            );
        }

        return (
            <div className="odometer-outer" ref={this.odometerRef}>
                <InlineSVG src={OdometerSVG} className={classes} data-prediction={this.getPredictionLevel()}/>
            </div>
        );
    }

    renderSignupForNotificationsModal = () => {
        if (!this.state.showSignupForNotifications) {
            return null;
        }

        return (
            <SignupForNotifications onClose={this.hideSignupForNotifications} onSuccessCallback={this.signedUpForNotifications} initialSignupData={{postalCode: this.state.postalCode}}/>
        );
    };

    renderMunicipality(statistics) {
        if (!statistics.municipality) {
            return;
        }

        return <>
            <span className="two-col__title-municipality"> in <Link to={'/gemeenten/' + statistics.municipality.slug} onClick={this.pushManicipalityToDataLayer.bind(this, statistics.municipality.name )} className="link-color">{statistics.municipality.name}</Link></span>
        </>;
    }
}

export default withRouter(connect()(PredictionResultViewB));

