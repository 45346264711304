import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import LockToolIntro from '../components/lock-tool/intro';
import LockToolSteps from '../components/lock-tool/steps';
import LockToolResult from '../components/lock-tool/result';
import Footer from '../components/footer';

const LockToolView = () => {
    let { path } = useRouteMatch();
    return (
        <>
            <Switch>
            <Route exact path={path}>
                <LockToolIntro />
            </Route>
            <Route path={`${path}/resultaat`}>
                <LockToolResult />
            </Route>
            <Route path={`${path}/:step`}>
                <LockToolSteps />
            </Route>
        </Switch>
            <Footer flatten />
        </>
    );
};

export default connect()(LockToolView);
