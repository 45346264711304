import React from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import Footer from '../components/footer';
import moment from 'moment';
import YearPredictionHistoryBarchart from '../components/year-prediction-history-barchart';
import BurglaryAlertCTA from "../components/burglary-alert-cta";
import PropTypes from 'prop-types';
import { NOT_FOUND as HTTP_NOT_FOUND, OK as HTTP_OK } from 'http-status-codes';
import pushToDataLayer from '../core-helpers/gtm';
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import HowItWorksModal from '../components/how-it-works-modal';

// Redux
import { getStore } from '../store';
import classnames from 'classnames';
import content from '../content';

// SVGs
import { ReactComponent as InfoIcon } from '../../../images/sprites/info-round.svg';
import Helmet from 'react-helmet';

class MunicipalityDetailView extends React.Component {

    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.object.isRequired
        })
    };

    constructor(props) {
        super(props);

        this.state = {
            municipalityCode: null,
            statistics: {},
            isFetching: false,
            showOnly12Months: false,
            zoom: 13,
        };
    }

    // How it works

    showHowItWorks() {
        this.setState({
            showHowItWorks: true
        });

        this.pushActionToDataLayer('HoeWerktHetPopup');
    }

    hideHowItWorks = () => {
        this.setState({
            showHowItWorks: false
        });
    };

    handleClickHowItWorks = (e) => {
        e.preventDefault();
        this.showHowItWorks();
    };

    renderHowItWorksModal = () => {
        if (!this.state.showHowItWorks) {
            return null;
        }

        return (
            <HowItWorksModal onClose={this.hideHowItWorks}/>
        );
    };

    // End how it works

    componentDidMount() {
        const { municipalityCode } = this.props.match.params;
        this.setState({
            municipalityCode
        });
        this.fetchStatistics(municipalityCode);
    }

    fetchStatistics = async(municipalityCode) => {
        if ( !municipalityCode ) {
            return;
        }

        this.setState({
            isFetching: true,
            statistics: {}
        });

        const { BASENAME } = getStore();
        const response = await fetch(`${BASENAME}/api/gemeente/${municipalityCode}`);

        this.setState({
            isFetching: false
        });

        switch ( response.status ) {
            case HTTP_OK: {
                const statistics = await response.json();

                return this.setState({
                    statistics
                });
            }
            case HTTP_NOT_FOUND: {
                const json = await response.json();

                alert(json.error); // eslint-disable-line no-alert
                window.location.href = BASENAME;

                return;
            }
            default: {
                return alert('Er ging iets mis tijdens het ophalen van de data, probeer het later nog eens'); // eslint-disable-line no-alert
            }
        }
    };

    pushActionToDataLayer = (action, postalCode, predictionLevel) => {
        pushToDataLayer({
            event: 'BurglaryToolAction',
            burglaryAction: action,
            burglaryPostalCode: postalCode,
            burglaryPredictionLevel: predictionLevel
        });
    };

    render() {
        return (
            <React.Fragment>
                {this.renderLoader()}
                {this.renderHelmet()}
                {this.renderBarchart()}
                {this.renderMapAndZipcodes()}
                <Footer flatten/>
                {this.renderHowItWorksModal()}
            </React.Fragment>
        );
    }

    renderMap() {
        const {
            lat,
            long
        } = this.state.statistics;

        if (!lat || !long) {
            return;
        }

        const {
            districts
        } = this.state.statistics;

        const position = [lat, long]
        return (
            <Map center={position} zoom={this.state.zoom}>
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
                />
                {this.renderMarkers(districts)}
            </Map>
        )
    }

    renderLoader() {
        const { isFetching } = this.state;
        const classes = classnames('loader-container', {
            'is-loading': isFetching
        });

        return (
            <div className={classes}>
                <div className="loader loader--large"></div>
            </div>
        );
    }

    renderBarchart() {
        if (!this.state.statistics.code) {
            return;
        }

        const {
            numberOfBurglaries,
            numberOfDaysSinceLastBurglary,
            monthlyBurglaries,
            name
        } = this.state.statistics;

        const {
        } = this.state;

        let entries = Object.entries(monthlyBurglaries);

        const maxValue = entries.reduce((carry, values) => {
            return carry > values[1] ? carry : values[1];
        }, 0);

        const chartData = entries.map((values) => {
            const month = values[0];
            const value = values[1];

            return {
                date: month,
                predictionLevel: Math.round(10 * value / maxValue),
                burglaries: value
            };
        });

        let period = {
            start: '',
            end: ''
        };

        if (chartData.length > 0) {
            period = {
                start: moment(chartData[0].date).format('MMMM YYYY'),
                end: moment(chartData[chartData.length - 1].date).format('MMMM YYYY')
            };
        }

        return (
            <section className="section">
                <div className="container">
                    <div className="municipality-page-intro">
                        <h1 className="municipality-page-intro__heading">Gemeente {name}</h1>
                    </div>
                    <div className="municipality-blocks">
                        <div className="municipality-blocks__item municipality-blocks__item--outbreak municipality-blocks__item--gray">
                            <div className="municipality-blocks__barchart-header">
                                <h3 className="municipality-blocks__heading">Aantal inbraken (en pogingen tot)</h3>
                                <div className="municipality-blocks__data">
                                    <span className="municipality-blocks__data-item">Totaal <strong>{numberOfBurglaries}</strong></span>
                                    <span className="municipality-blocks__data-item">Dagen sinds laatste inbraak <strong>{numberOfDaysSinceLastBurglary}</strong></span>
                                </div>
                            </div>
                            <YearPredictionHistoryBarchart months={chartData} className="barchart--municipality" />
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    renderMarkers(districts) {
        const risks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        const iconItems = risks.map((risk) =>
            L.divIcon({
                className: 'district-marker district-marker__risk' + risk,
                html: risk
            })
        );

        return (
            <>
                {districts.map((district, i) => (
                    <Marker key={`tool:${i}`} position={[district.latitude, district.longitude]} icon={iconItems[district.predictionLevel - 1]}>
                        <Popup className="gtm-municipality-map-tooltip-open" onOpen={this.pushActionToDataLayer.bind(this, 'OpenToolTipKaart', district.postalCode, district.predictionLevel)} offset={[11, 0]}>
                            <div className="marker-tooltip">
                                <div className="marker-tooltip__heading">
                                    <h4>{district.name} {district.postalCode}</h4>
                                    <div className={'district-marker district-marker--small district-marker__risk' + district.predictionLevel}>{district.predictionLevel}</div>
                                </div>
                                <div className="marker-tooltip__body">
                                    <h5 className="marker-tooltip__body-heading">
                                        Inbraakcijfers (laatste 12 maanden)
                                    </h5>
                                    <dl>
                                        <dt>Aantal inbraken</dt>
                                        <dd>{district.numberOfBurglaries}</dd>
                                        <dt>Aantal pogingen tot inbraak</dt>
                                        <dd>{district.numberOfBurglaryAttempts}</dd>
                                        <dt>Aantal dagen sinds laatste inbraak</dt>
                                        <dd>{district.numberDaysSinceLastBurglary || 0}</dd>
                                    </dl>
                                </div>
                                <div className="marker-tooltip__link-holder">
                                    <Link to={`/resultaat#${district.postalCode}`} className="marker-tooltip__link" onClick={this.pushActionToDataLayer.bind(this, 'BekijkRisicoGemeenteDetailViaKaart', district.postalCode, district.predictionLevel)}>Bekijk postcode</Link>
                                </div>
                            </div>
                        </Popup>
                    </Marker>
                ))}
            </>
        );
    }

    renderMetadata() {
        if (!this.state.statistics.code) {
            return;
        }

        const { metadata } = this.state.statistics;

        if (!metadata) {
            return;
        }

        const formattedPopulationSize = new Intl.NumberFormat('nl-NL').format(metadata.populationSize);
        const formattedAverageNumberOfPersonsPerHousehold = new Intl.NumberFormat('nl-NL').format(metadata.averageNumberOfPersonsPerHousehold);

        return (
            <>
                <div className="municipality-metadata-text">
                    Deze gemeente telt <span>{formattedPopulationSize}</span> inwoners.
                    Zij hebben een gemiddelde leeftijd van <span>{metadata.averageAge}</span> jaar oud.
                    Daarnaast telt elk huishouden gemiddeld <span>{formattedAverageNumberOfPersonsPerHousehold}</span> personen.
                </div>
            </>
        );
    }

    renderHelmet() {
        if (!this.state.statistics.code) {
            return;
        }

        const { name, numberOfBurglaries, numberOfDaysSinceLastBurglary } = this.state.statistics;
        return (
            <Helmet>
                <title>{name} - Inbraakbarometer</title>
                <meta property="og:title" content={`${name} - Inbraakbarometer`} />
                <meta name="description" content={`In ${name} waren er de laatste 2 jaar ${numberOfBurglaries} inbraken (en pogingen tot). De laatste inbraak was ${numberOfDaysSinceLastBurglary} dagen geleden. Benieuwd naar het inbraakrisico in jouw wijk?`} />
                <meta property="og:description" content={`In ${name} waren er de laatste 2 jaar ${numberOfBurglaries} inbraken (en pogingen tot). De laatste inbraak was ${numberOfDaysSinceLastBurglary} dagen geleden. Benieuwd naar het inbraakrisico in jouw wijk?`} />
                <script type="application/ld+json">{`
                    {
                        "@context":"https://schema.org",
                        "@type":"BreadcrumbList",
                        "itemListElement":[
                            {
                                "@type":"ListItem",
                                "position":1,
                                "name":"Gemeenten",
                                "item":"${window.location.origin}/gemeenten"
                            },
                            {
                                "@type":"ListItem",
                                "position":2,
                                "name": "${name}"
                            }
                        ]
                    }
                `}</script>
            </Helmet>
        )
    }

    renderMapAndZipcodes() {
        if (!this.state.statistics.code) {
            return;
        }

        const {
            districts
        } = this.state.statistics;

        return (
            <>
                <section className="section">
                    <div className="container">
                        <div className="municipality-blocks municipality-blocks--map-table">
                            <div className="municipality-blocks__item municipality-blocks__item--map">
                                {this.renderMap()}
                                {this.renderMetadata()}
                            </div>
                            <div className="municipality-blocks__item municipality-blocks__item--table municipality-blocks__item--outbreak">
                                <div className="municipality-blocks__table-header">
                                    <h2>Inbraakcijfers (laatste 12 maanden)</h2>
                                    <button className="how-it-works-link how-it-works-link--flat" onClick={this.handleClickHowItWorks}><InfoIcon className="how-it-works-link__icon"/> {content.statistics.odometer.howItWorks}</button>
                                </div>
                                <div className="municipality-table-holder municipality-table-holder--outbreak">
                                    <table className="municipality-table">
                                        <thead>
                                        <tr>
                                            <th>Buurt</th>
                                            <th>Inbraken</th>
                                            <th>Pogingen</th>
                                            <th>Risico</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.renderZipcodeRows(districts)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <BurglaryAlertCTA />
            </>
        );
    }

    renderZipcodeRows(districts) {
        return (
            <>
                {districts.map((district, i) => (
                    <tr key={`tool:${i}`}>
                        <td>
                            <Link to={`/resultaat#${district.postalCode}`} className="municipality-table__link" onClick={this.pushActionToDataLayer.bind(this, 'BekijkRisicoGemeenteDetail', district.postalCode, district.predictionLevel)}>{district.postalCode}</Link>
                            <div className="municipality-table__district-name">{district.name}</div>
                        </td>
                        <td>{district.numberOfBurglaries}</td>
                        <td>{district.numberOfBurglaryAttempts}</td>
                        <td><div data-prediction={district.predictionLevel} className="municipality-table__risk">{district.predictionLevel}</div></td>
                    </tr>
                ))}
            </>
        );
    }
}

export default connect()(MunicipalityDetailView);
