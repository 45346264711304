import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import Modal from '../core-components/modal';
import ReCAPTCHA from "react-google-recaptcha";

// Content
import content from '../content';

// Redux
import { getStore } from '../store';

// Helpers
import { getProfile } from '../core-helpers/content';

// SVGs
import { ReactComponent as CheckIconSVG } from '../../../images/sprites/check.svg';
import { ReactComponent as BurglaryNotificationSVG } from '../../../images/sprites/burglary-notification.svg';



export default class SignupForNotifications extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSuccessCallback: PropTypes.func,
        initialSignupData: PropTypes.object
    };

    static defaultProps = {
        initialSignupData: {postalCode: ''}
    };

    constructor(props) {
        super(props);
        this.state = {
            email: getProfile().email || '',
            token: '',
            optIn: false,
            isSubmitting: false,
            signedUp: false,
            signupData: this.props.initialSignupData
        };
    };

    handleChange = (token) => {
        this.setState({
            token: token
        });
        this.handleSubmitSignup();
    }

    handleChangeEmail = (e) => {
        this.setState({
            email: e.target.value
        });
    };

    handleChangePostalCode = (e) => {
        this.setState({
            signupData: {postalCode: e.target.value}
        });
    };

    handleChangeOptIn = (e) => {
        this.setState({
            optIn: e.target.checked
        });
    };

    handleSubmit = (e, recaptchaRef) => {
        e.preventDefault();
        recaptchaRef.current.execute();
    }

    handleSubmitSignup = async() => {
        const { email, optIn, signupData, token } = this.state;
        const { onSuccessCallback } = this.props;

        this.setState({
            isSubmitting: true
        });

        const { BASENAME } = getStore();
        const response = await fetch(`${BASENAME}/api/notificatie/inschrijven`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...signupData,
                email,
                optIn,
                token
            })
        });

        const json = await response.json();
        this.setState({
            isSubmitting: false
        });

        if ( json.error ) {
            return alert(json.error); // eslint-disable-line no-alert
        }

        this.setState({
            signedUp: true
        });

        if (onSuccessCallback) {
            onSuccessCallback();
        }
    };

    render() {
        const { onClose } = this.props;
        const { isSubmitting, signedUp } = this.state;
        const classes = classnames('button button--small', {
            'is-loading': isSubmitting
        });

        if (signedUp) {
            return (
                <Modal onClose={onClose}>
                    <h2>{content.signupForNotifications.signedUp}</h2>
                    <p>{content.signupForNotifications.signedUpText}</p>
                </Modal>
            );
        }
        const recaptchaRef = React.createRef();

        return (
            <Modal onClose={onClose}>
                <h2>Meld je aan voor de inbraak-alert</h2>
                <div className="icon-and-list">
                    <BurglaryNotificationSVG className="icon-and-list__icon" />
                    <div className="icon-and-list__list">
                        <h3 className="icon-and-list__heading">Net als 5993 anderen ontvang je</h3>
                        <ul>
                            <li>
                                <CheckIconSVG className="icon-and-list__list-icon" />
                                Bericht als het inbraakrisico in jouw wijk verhoogt
                            </li>
                            <li>
                                <CheckIconSVG className="icon-and-list__list-icon" />
                                Inzicht in de actuele inbraaktrends in Nederland
                            </li>
                            <li>
                                <CheckIconSVG className="icon-and-list__list-icon" />
                                Tips om inbraak te voorkomen
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className="modal__splitter" />
                <form className="form" onSubmit={(e) => {
                    e.preventDefault();
                    recaptchaRef.current.execute();
                }}>
                    <label className="form__label form__label--small">{content.signupForNotifications.form.label}</label>
                    <input
                        type="email"
                        className="form__field"
                        value={this.state.email}
                        placeholder={content.signupForNotifications.form.placeholder}
                        onChange={this.handleChangeEmail}
                    />
                    <label className="form__label form__label--small">Postcode</label>
                    <input
                        type="text"
                        pattern="^[1-9][0-9]{3}"
                        min="0" maxLength="4"
                        value={this.state.signupData.postalCode}
                        placeholder="5041"
                        onChange={this.handleChangePostalCode}
                        className="form__field"
                    />
                    <div className="form-choice form-choice--checkbox">
                        <input type="checkbox" id="newsletter" className="form-choice__input" checked={this.state.optIn} onChange={this.handleChangeOptIn}/>
                        <label htmlFor="newsletter" className="form-choice__label">{content.signupForNotifications.form.newsletter}</label>
                    </div>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        onChange={this.handleChange}
                        sitekey="6Lf2evkUAAAAAGGEGdov6UB4KTkmhkSKv8Xw1uJH"
                    />
                    <div className="form__footer">
                        <button type="submit" className={classes} disabled={isSubmitting}>{content.signupForNotifications.form.button}</button>
                        <em>{content.signupForNotifications.form.privacy}</em>
                    </div>
                </form>
            </Modal>
        );
    }
}
