import React from 'react';
import oplegSlotImage from '../../../../../images/locktool/oplegslot-popup.jpg';
import insteekSlotImage from '../../../../../images/locktool/insteekslot-pop-up.png';

// Components
import Modal from '../../../core-components/modal';

import PropTypes from 'prop-types';

const CylinderInstallationTypeModal = ({ onClose }) => {
    return (
        <Modal onClose={onClose}>
            <h2>Wat voor type voordeurslot heb ik?</h2>
            <h3>Oplegslot</h3>
            <p>
                Een oplegslot komt vooral voor bij oudere huizen in grote
                steden. Je kan een oplegslot herkennen aan de slotkast die
                bevestigd is op de binnenkant van de deur. Zoals op de
                afbeelding hieronder.
            </p>
            <img src={oplegSlotImage} alt="Oplegslot" /> <br />
            <h3>Insteekslot</h3>
            <p>
                Het insteekslot is een van de meest gebruikte cilindersloten.
                Het grootste deel van een slot zit verzonken in de deur. Een
                insteekslot beveiligd de deur op 1 punt. Je kan een insteekslot
                herkennen aan de afdekplaat aan de kopse kant van de deur. Zoals
                op de afbeelding hieronder.
            </p>
            <img src={insteekSlotImage} alt="Insteekslot" /> <br />
            <h3>Meerpuntssluiting</h3>
            <p>
                Een meerpuntssluiting is een ander soort insteekslot. Ook dit
                slot zit voor het grootste deel verzonken in de deur. In
                tegenstelling tot een normaal insteekslot beveiligd een
                meerpuntssluiting de deur op ten minste 3 plekken. Dat terwijl
                je het met maar één sleutel bedient. Je kan het herkennen aan de
                meerdere slotgaten in het kozijn en de kopse kant van de deur.De
                slotgaten zijn meestal verdeeld over de gehele lengte van je
                deur.
            </p>
        </Modal>
    );
};


CylinderInstallationTypeModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default CylinderInstallationTypeModal;
