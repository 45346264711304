import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import content from '../content';
import classnames from 'classnames';

const predictionLevelTexts = {
    0: 'Onbekend',
    1: 'zeer laag',
    2: 'zeer laag',
    3: 'laag',
    4: 'laag',
    5: 'middelmatig',
    6: 'middelmatig',
    7: 'hoog',
    8: 'hoog',
    9: 'zeer hoog',
    10: 'zeer hoog',
};

class PredictionHistoryBarchart extends React.Component {
    static propTypes = {
        days: PropTypes.arrayOf(PropTypes.shape({
                date: PropTypes.string.isRequired,
                predictionLevel: PropTypes.number.isRequired
            }))
            .isRequired
    };

    _isToday = (date) => {
        return date === moment().format('YYYY-MM-DD');
    };

    _isPassedDate = (date) => {
        return moment(date).isBefore(moment(), 'days');
    };

    _getDayClasses = (date, predictionLevel) => {
        return classnames('barchart__bar', {
            'barchart__bar--highlighted': this._isToday(date),
            'barchart__bar--inactive': predictionLevel === 0,
            'barchart__bar--passed-date': this._isPassedDate(date)
        });
    };

    _getPredictionTooltipText = (predictionLevel) => {
        if (predictionLevel === 0) {
            return predictionLevelTexts[predictionLevel];
        }

        return `${predictionLevel} (${predictionLevelTexts[predictionLevel]})`;
    };

    render() {
        const days = this.props.days;

        return (
            <div className="barchart">
                {days.map((day) => (
                    <div key={day.date} data-prediction={day.predictionLevel} className={this._getDayClasses(day.date, day.predictionLevel)}>
                        <div className="barchart__bar-fill-wrap">
                            <div className="barchart__bar-fill">
                                <div className="barchart__tooltip">
                                    <span className="barchart__tooltip-title">{content.predictionHistoryBarchart.tooltipTitle}</span>
                                    <span className="barchart__tooltip-text">{this._getPredictionTooltipText(day.predictionLevel)}</span>
                                </div>
                            </div>
                        </div>
                        <span className="barchart__label">{moment(day.date).format('dd')}</span>
                        <span className="barchart__date">{this._isToday(day.date) ? ' Vandaag' : ''}</span>
                    </div>
                ))}
            </div>
        );
    }
}

export default PredictionHistoryBarchart;
