import React from 'react';

const defaultValue = {
    cylinderInstallationType: null,
    setCylinderInstallationType: () => null,
    starsOnLock: null,
    setStarsOnLock: () => null,
    cylinderInstallation: null,
    setCylinderInstallation: () => null,
    starsOnCylinder: null,
    setStarsOnCylinder: () => null,
    screwsOnShield: null,
    setScrewsOnShield: () => null,
    resetForm: () => null,
    formData: {},
};

export const LockToolContext = React.createContext(defaultValue);
