import React, {useContext, useEffect} from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import uitsteekImage from '../../../../images/locktool/uitsteek.jpg';
import verzonkenImage from '../../../../images/locktool/verzonken.jpg';
import antikerntrekImage from '../../../../images/locktool/antikerntrek.jpg';
import LockToolFooter from './footer';
import QuestionOption from './question-option';
import { LockToolContext } from '../../context/lockToolContext';

const LockToolQuestionCylinderInstallation = ({ history }) => {
    const lockToolFormController = useContext(LockToolContext);
    const { register, handleSubmit, watch } = useForm();
    const watchCylinderInstallation = watch('cylinderInstallation');

    useEffect(() => {
        if (
            lockToolFormController.starsOnLock === null ||
            lockToolFormController.cylinderInstallationType === null
        ) {
            history.push('./');
        }
    }, []);

    const onSubmit = (data) => {
        lockToolFormController.setCylinderInstallation(
            data.cylinderInstallation
        );
        history.push('./stap4');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="locktool">
            <div className="locktool__content">
                <div className="container">
                    <div className="locktool__header">
                        <div className="locktool__meta">
                            <div className="locktool__count">3/5</div>
                        </div>
                        <h1 className="locktool__title">
                            Steekt de cilinder uit aan de buitenkant van de
                            deur?
                        </h1>
                        <div className="locktool__more-info">
                            De cilinder van je slot is het gedeelte waar je de
                            sleutel in stopt. Een cilinder mag wel iets
                            uitsteken, maar niet meer dan 3 millimeter. Heb jij
                            een cilinder die minder dan 3mm uitsteekt? Dan
                            beschouwen we dit als een verzonken cilinder.
                        </div>
                    </div>

                    <div className="question-group">
                        <QuestionOption
                            name="cylinderInstallation"
                            value="protrude"
                            title="Ja, de cilinder steekt meer dan 3mm uit"
                            img={uitsteekImage}
                            register={register}
                        />
                        <QuestionOption
                            name="cylinderInstallation"
                            value="recessed"
                            title="Nee, de cilinder ligt verzonken"
                            img={verzonkenImage}
                            register={register}
                        />
                        <QuestionOption
                            name="cylinderInstallation"
                            value="core-pulling-protection"
                            title="Nee, de cilinder ligt achter een kerntrekbeveiliging"
                            img={antikerntrekImage}
                            register={register}
                        />
                    </div>
                </div>
            </div>
            <LockToolFooter>
                <Link to="./stap2">Vorige</Link>
                <input
                    className="button"
                    type="submit"
                    value="Volgende"
                    disabled={!watchCylinderInstallation}
                />
            </LockToolFooter>
        </form>
    );
};

LockToolQuestionCylinderInstallation.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(LockToolQuestionCylinderInstallation);
