import React from 'react';
import { connect } from 'react-redux';
import PredictionResultViewA from '../components/prediction-result-a';
import PredictionResultViewB from '../components/prediction-result-b';
import { Experiment, Variant } from "react-optimize";
import { useLocation } from 'react-router-dom';

const PredictionResultView = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    if (params.has('test') && params.get('test') === 'b') {
        return <PredictionResultViewB />;
    }

    if (params.has('test') && params.get('test') === 'a') {
        return <PredictionResultViewA />;
    }

    return (
      <Experiment id="drRwkywrTROwJD3nJCO0YQ">
        <Variant id="0">
          <PredictionResultViewA />
        </Variant>
        <Variant id="1">
          <PredictionResultViewB />
        </Variant>
      </Experiment>
    )
};

export default connect()(PredictionResultView);
