import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { PoseGroup } from 'react-pose';
import PropTypes from 'prop-types';
import classnames from 'classnames/dedupe';
import moment from 'moment';
import pushToDataLayer from './core-helpers/gtm';

// Store
import createStore from './store';
import LockToolContextProvider from './context/LockToolContextProvider';

// Components
import ErrorBoundary from './core-components/error-boundary';
import RoutesContainer from './core-components/routes-container';
import ScrollToTop from './core-components/scroll-to-top';

// Helpers
import { isSlimThuisWebview } from './core-helpers/content';
import { trackRoute } from './core-helpers/analytics';

// Modernizr
import '../../../frontend/.modernizrrc';

// Configuration
moment.locale('nl');

export default class App extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        basename: PropTypes.string.isRequired,
        reducers: PropTypes.objectOf(PropTypes.func),
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
        ]),
        className: PropTypes.string,
    };

    constructor(props) {
        super(props);

        const { id, basename, reducers } = props;
        const { store, persistor, history } = createStore(
            id,
            basename,
            reducers
        );
        this.store = store;
        this.persistor = persistor;
        this.history = history;

        if (isSlimThuisWebview()) {
            // Disable zoom to give the app a more native feel
            const viewport = document.querySelector('meta[name="viewport"]');
            viewport.setAttribute(
                'content',
                `${viewport.getAttribute(
                    'content'
                )},minimum-scale=1,maximum-scale=1,user-scalable=no`
            );
        }

        trackRoute(history.location.pathname);
        history.listen((location) => {
            trackRoute(location.pathname);
            pushToDataLayer({
                event: 'PageView',
                pagePath:
                    basename +
                    location.pathname +
                    location.search +
                    location.hash,
            });
        });
    }

    componentDidMount() {
        const { className } = this.props;

        document.querySelector('.app').className = classnames(
            'app',
            {
                'is-slimthuis-webview': isSlimThuisWebview(),
            },
            className
        );
    }

    render() {
        const { children } = this.props;

        return (
            <ErrorBoundary>
                <LockToolContextProvider>
                    <Provider store={this.store}>
                        <PersistGate persistor={this.persistor}>
                            <ConnectedRouter history={this.history}>
                                <ScrollToTop>
                                    <Route
                                        render={({ location }) => (
                                            <PoseGroup animateOnMount>
                                                <RoutesContainer
                                                    className="routes-container"
                                                    location={location}
                                                    history={this.history}
                                                    key={location.pathname}
                                                >
                                                    <Switch location={location}>
                                                        <Route
                                                            path="/"
                                                            render={() =>
                                                                children
                                                            }
                                                        />
                                                    </Switch>
                                                </RoutesContainer>
                                            </PoseGroup>
                                        )}
                                    />
                                </ScrollToTop>
                            </ConnectedRouter>
                        </PersistGate>
                    </Provider>
                </LockToolContextProvider>
            </ErrorBoundary>
        );
    }
}
