import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

// Constants
import { TRANSITION_DURATION } from './routes-container';

class ScrollToTop extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ])
    };

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        const state = location.state || {};

        if ( location === prevProps.location ) {
            return;
        }

        if ( state.scroll === false ) {
            return;
        }

        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: state.smooth ? 'smooth' : 'auto'
            });
        }, state.transition === false ? 0 : TRANSITION_DURATION);
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);
