import React from 'react';
import {connect} from 'react-redux';

// Components
import Footer from '../components/footer';
import PredictCTA from '../components/predict-cta';
import PropTypes from 'prop-types';
import { NOT_FOUND as HTTP_NOT_FOUND, OK as HTTP_OK } from 'http-status-codes';
import pushToDataLayer from '../core-helpers/gtm';

// Redux
import { getStore } from '../store';
import moment from 'moment';
import {Link} from 'react-router-dom';
import classnames from 'classnames';

// SVGs
import { ReactComponent as ChevronDownIconSVG } from '../../../images/sprites/chevron-down.svg';
import Helmet from 'react-helmet';


class MunicipalityOverviewView extends React.Component {

    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.object.isRequired
        })
    };

    constructor(props) {
        super(props);

        this.state = {
            statistics: {},
            numberOfItems: 10,
            isFetching: false,
            sortConfig: {
                field: 'numberOfBurglaries',
                direction: -1,
            },
            sortedMunicipalities: []
        };

        // This binding is necessary to make `this` work in the callback
        this.showMoreManicipalitiesRows = this.showMoreManicipalitiesRows.bind(this);
    }

    async componentDidMount() {
        await this.fetchStatistics();
        this.sortTable();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.statistics.municipalities) {
            return;
        }
        if (prevState.sortConfig !== this.state.sortConfig) {
            this.sortTable();
        }
    }

    fetchStatistics = async() => {
        this.setState({
            isFetching: true,
            statistics: {}
        });

        const { BASENAME } = getStore();
        const response = await fetch(`${BASENAME}/api/gemeenten`);

        this.setState({
            isFetching: false
        });

        switch ( response.status ) {
            case HTTP_OK: {
                const statistics = await response.json();
                return this.setState({
                    statistics
                });
            }
            case HTTP_NOT_FOUND: {
                const json = await response.json();

                alert(json.error); // eslint-disable-line no-alert
                window.location.href = BASENAME;

                return;
            }
            default: {
                return alert('Er ging iets mis tijdens het ophalen van de data, probeer het later nog eens'); // eslint-disable-line no-alert
            }
        }
    };

    pushActionToDataLayer = (action, municipality) => {
        pushToDataLayer({
            event: 'BurglaryToolAction',
            burglaryAction: action,
            burglaryManicipality: municipality
        });
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Gemeenten - Inbraakbarometer</title>
                    <meta property="og:title" content="Gemeenten - Inbraakbarometer" />
                        <meta name="description" content="Bekijk in één oogopslag hoeveel er wordt ingebroken in jouw gemeente. De ene gemeente is namelijk meer in trek bij inbrekers dan de andere." />
                        <meta property="og:description" content="Bekijk in één oogopslag hoeveel er wordt ingebroken in jouw gemeente. De ene gemeente is namelijk meer in trek bij inbrekers dan de andere." />
                </Helmet>
                <div className="wrapper">
                    {this.renderLoader()}
                    {this.renderTable()}
                    <PredictCTA />
                    <Footer flatten/>
                </div>
            </React.Fragment>
        );
    }

    renderLoader() {
        const { isFetching } = this.state;
        const classes = classnames('loader-container', {
            'is-loading': isFetching
        });

        return (
            <div className={classes}>
                <div className="loader loader--large"></div>
            </div>
        );
    }

    renderTable() {
        if (!this.state.statistics.municipalities) {
            return;
        }

        const {
            startDate,
            endDate
        } = this.state.statistics;

        return (
            <section className="section">
                <div className="container">
                    <div className="municipality-page-intro municipality-page-intro--half">
                        <h1 className="municipality-page-intro__heading">Gemeenten</h1>
                        <span className="municipality-page-intro__subheading">{moment(startDate.date).format('MMMM YYYY')} - {moment(endDate.date).format('MMMM YYYY')}</span>
                        <p>Woninginbraken komen overal voor. De ene gemeente is meer in trek bij inbrekers dan de andere. In deze lijst vind je alle gemeenten van Nederland. Zo zie je in één oogopslag in welke gemeente het meest werd ingebroken in de laatste 12 maanden.</p>
                    </div>
                    <div className="municipality-blocks municipality-blocks--scrollable">
                        <div className="municipality-blocks__item municipality-blocks__item--table municipality-blocks__item--full municipality-blocks__item--outbreak">
                            <div className="municipality-table-holder municipality-table-holder--outbreak">
                                <table className="municipality-table">
                                    <thead>
                                    <tr>
                                        <th>
                                            <button type="button" className={`municipality-table__button--sort gtm-sorteer-op-gemeente ${this.state.sortConfig.field === 'name' ? 'active' : ''} ${this.state.sortConfig.direction === 1 ? 'up' : 'down'}`} onClick={() => this.requestSort('name')}>
                                                Gemeente
                                            </button>
                                        </th>
                                        <th>
                                            <button type="button" className={`municipality-table__button--sort gtm-sorteer-op-provincie ${this.state.sortConfig.field === 'provinceName' ? 'active' : ''} ${this.state.sortConfig.direction === 1 ? 'up' : 'down'}`} onClick={() => this.requestSort('provinceName')}>
                                                Provincie
                                            </button>
                                        </th>
                                        <th>
                                            <button type="button" className={`municipality-table__button--sort gtm-sorteer-op-inbraken ${this.state.sortConfig.field === 'numberOfBurglaries' ? 'active' : ''} ${this.state.sortConfig.direction === 1 ? 'up' : 'down'}`} onClick={() => this.requestSort('numberOfBurglaries')}>
                                                Inbraken
                                            </button>
                                        </th>
                                        <th>
                                            <button type="button" className={`municipality-table__button--sort gtm-sorteer-op-pogingen  ${this.state.sortConfig.field === 'numberOfBurglaryAttempts' ? 'active' : ''} ${this.state.sortConfig.direction === 1 ? 'up' : 'down'}`} onClick={() => this.requestSort('numberOfBurglaryAttempts')}>
                                                Pogingen
                                            </button>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderManicipalitiesRows()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    requestSort(key) {
        let direction = 1;
        const {sortConfig} = this.state;

        if (sortConfig.field === key && sortConfig.direction === 1) {
            direction = -1;
        }

        this.setState({sortConfig: {
            field: key,
            direction: direction,
        }});
    }

    sortTable() {
        const { municipalities } = this.state.statistics;
        const {direction, field} = this.state.sortConfig;
        let sortedRows = [...municipalities];
        if (field !== null) {
            sortedRows.sort((a, b) => {
                if (typeof a[field] === 'string') {
                    if (a[field].toLowerCase() < b[field].toLowerCase()) {
                        return -1 * direction;
                    }
                    if (a[field].toLowerCase() > b[field].toLowerCase()) {
                        return 1 * direction;
                    }
                } else {
                    if (a[field] < b[field]) {
                        return -1 * direction;
                    }
                    if (a[field] > b[field]) {
                        return 1 * direction;
                    }
                }
                return 0;
            });
        }

        this.setState({
            sortedMunicipalities: [...sortedRows]
        })
    }

    showMoreManicipalitiesRows(e) {
        e.preventDefault();
        this.setState({
            numberOfItems: this.state.statistics.municipalities.length,
        });
    }

    renderManicipalitiesRows() {
        const {
            numberOfItems
        } = this.state;
        const {sortedMunicipalities} = this.state;

        return (
            <>
                {sortedMunicipalities.slice(0, numberOfItems).map((municipality, i) => (
                    <tr key={`tool:${i}`}>
                        <td><Link to={`/gemeenten/${municipality.slug}`} className="municipality-table__link" onClick={this.pushActionToDataLayer.bind(this, 'BekijkGemeenteVanuitOverzicht', municipality.name)}>{municipality.name}</Link></td>
                        <td>{municipality.provinceName}</td>
                        <td>{municipality.numberOfBurglaries}</td>
                        <td>{municipality.numberOfBurglaryAttempts}</td>
                    </tr>
                ))}
                {(numberOfItems !== this.state.statistics.municipalities.length) && (
                    <tr>
                        <td colSpan="4" className='municipality-table__show-all'>
                            <a href="#" onClick={this.showMoreManicipalitiesRows} className="gtm-toon-alle-gemeenten">Toon alle gemeenten <ChevronDownIconSVG className="municipality-table__icon"/></a>
                        </td>
                    </tr>
                )}
            </>
        );
    }
}

export default connect()(MunicipalityOverviewView);
