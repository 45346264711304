import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import InlineSVG from 'svg-inline-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';
import pushToDataLayer from '../core-helpers/gtm';
import {
    OK as HTTP_OK,
    BAD_REQUEST as HTTP_BAD_REQUEST
} from 'http-status-codes';

// Components
import SignupForNotifications from '../components/signup-for-notifications';
import Footer from '../components/footer';
import PredictionHistoryBarchart from '../components/prediction-history-barchart';
import StatisticsListItem from '../components/statistics-list-item';
import StatisticsBlockListItem from '../components/statistics-blocklist-item';

// Redux
import { getStore } from '../store';

// Content
import content from '../content';

// SVGs
import OdometerSVG from 'svg-inline-loader!../../../odometer/odometer.svg';
import { ReactComponent as PeopleIconSVG } from '../../../images/sprites/people.svg';
import { ReactComponent as HouseIconSVG } from '../../../images/sprites/house.svg';
import { ReactComponent as CakeIconSVG } from '../../../images/sprites/cake.svg';
import { ReactComponent as WhatsAppLogo } from '../../../images/sprites/whatsapp.svg';
import { ReactComponent as InfoIcon } from '../../../images/sprites/info-round.svg';
import { ReactComponent as BellIconSVG } from '../../../images/sprites/bell.svg';
import { ReactComponent as ChevronRightIconSVG } from '../../../images/sprites/chevron-right.svg';

// Components
import HowItWorksModal from '../components/how-it-works-modal';
import PreventionTip from "../components/prevention-tip";
import Helmet from 'react-helmet';

// Constants
const ODOMETER_ANIMATION_DURATION = 250;

class PredictionResultViewA extends React.Component {
    odometerRef = React.createRef();

    static propTypes = {
        location: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            postalCode: null,
            statistics: {},
            preventionTips: [],
            showHowItWorks: false,
            showSignupForNotifications: false,
            activeStepCTA: null,
            isFetching: false
        };
    }

    componentDidMount() {
        this.updatePostalCode();
        this.fetchPreventionTips();
    }

    componentDidUpdate(prevProps, prevState) {
        this.updatePostalCode();

        // TM-1294: Force a repaint on the odometer SVG in Internet Explorer once the animation finishes
        const ua = window.navigator.userAgent;
        if ( ua.includes('MSIE ') || ua.includes('Trident/') && prevState.statistics.postalCode !== this.state.statistics.postalCode ) {
            setTimeout(() => {
                this.odometerRef.current.style.opacity = 0.999;
                setTimeout(() => {
                    this.odometerRef.current.style.opacity = 1;
                }, ODOMETER_ANIMATION_DURATION);
            }, ODOMETER_ANIMATION_DURATION);
        }
    }

    fetchData = async() => {
        const { postalCode } = this.state;
        if ( !postalCode ) {
            return;
        }

        this.setState({
            isFetching: true,
            statistics: {}
        });

        const { BASENAME } = getStore();
        const response = await fetch(`${BASENAME}/api/voorspelling/${postalCode}`);

        this.setState({
            isFetching: false
        });

        switch ( response.status ) {
            case HTTP_OK: {
                const json = await response.json();
                pushToDataLayer({
                    event: 'BurglaryToolAction',
                    burglaryAction: 'StatistiekenGeladen',
                    burglaryPostalCode: json.postalCode,
                    burglaryPredictionLevel: json.prediction?.level ? json.prediction.level : undefined
                });
                return this.setStatistics(json);
            }
            case HTTP_BAD_REQUEST: {
                const json = await response.json();

                alert(json.error); // eslint-disable-line no-alert
                window.location.href = BASENAME;

                return;
            }
            default: {
                return alert('Er ging iets mis tijdens het ophalen van de data, probeer het later nog eens'); // eslint-disable-line no-alert
            }
        }
    };

    setStatistics = (statistics) => {
        this.setState({
            statistics
        });
    };

    fetchPreventionTips = async() => {
        this.setState({
            isFetching: true
        });

        const { BASENAME } = getStore();
        const response = await fetch(`${BASENAME}/api/preventietips`);

        this.setState({
            isFetching: false
        });

        switch ( response.status ) {
            case HTTP_OK: {
                const json = await response.json();
                return this.setPreventionTips(json);
            }
            case HTTP_BAD_REQUEST: {
                const json = await response.json();

                alert(json.error); // eslint-disable-line no-alert
                window.location.href = BASENAME;

                return;
            }
            default: {
                return alert('Er ging iets mis tijdens het ophalen van de data, probeer het later nog eens'); // eslint-disable-line no-alert
            }
        }
    };

    setPreventionTips = (preventionTips) => {
        this.setState({
            preventionTips: preventionTips.filter((preventionTip) => preventionTip.showOnResultPage)
        });
    };

    getPostalCodeFromURL() {
        return window.location.hash.replace(/^#/, '');
    }

    updatePostalCode() {
        const postalCode = this.getPostalCodeFromURL();

        if (postalCode !== this.state.postalCode) {
            this.setState({
                postalCode: postalCode
            }, this.fetchData);
        }
    }

    // Signup for notifications

    onClickShowSignupForNotifications = (e) => {
        e.preventDefault();

        this.pushActionToDataLayer('NotificatiesPopup');

        this.showSignupForNotifications();
    };

    signedUpForNotifications = () => {
        this.pushActionToDataLayer('NotificatiesAangemeld');
    };

    showSignupForNotifications = () => {
        this.setState({
            showSignupForNotifications: true
        });
    };

    hideSignupForNotifications = () => {
        this.setState({
            showSignupForNotifications: false
        });
    };

    // End Signup for notifications

    getPredictionLevel = () => {
        const { statistics } = this.state;
        return ((prediction) => {
            if ( !Object.values(statistics).length ) {
                return '';
            }

            if ( typeof prediction === 'undefined' ) {
                return 'unknown';
            }

            return statistics.prediction.level;
        })(statistics.prediction);
    };

    render() {
        const { preventionTips, postalCode, statistics } = this.state;
        const prediction = ((statistics.prediction && statistics.prediction.level) ? statistics.prediction.level : 0).toString();

        return (
            <React.Fragment>
                {this.renderHelmet()}
                {this.renderHowItWorksModal()}
                {this.renderSignupForNotificationsModal()}
                {this.renderIntro()}
                {this.renderStatisticHistory()}
                {this.renderPreventionTips(preventionTips, postalCode, prediction)}
                <Footer flatten/>
            </React.Fragment>
        );
    }

    renderHelmet() {
        if (!this.state.statistics || Object.keys(this.state.statistics).length === 0 && this.state.statistics.constructor === Object) {
            return;
        }
        const { postalCode, statistics } = this.state;
        const { municipality, numberOfDaysSinceLastBurglary, prediction} = statistics;

        return (
            <Helmet>
                <title>{`Inbraakrisico ${postalCode} - Inbraakbarometer`}</title>
                <meta property="og:title" content={`Inbraakrisico ${postalCode} - Inbraakbarometer`} />
                <meta name="description" content={`Het inbraakrisico in ${postalCode} in ${municipality.name} is ${this._getPredictionText(prediction.level)}. De laatste inbraak was ${numberOfDaysSinceLastBurglary} dagen geleden. Ontvang een bericht wanneer het inbraakrisico jouw wijk hoger wordt.`} />
                <meta property="og:description" content={`Het inbraakrisico in ${postalCode} in ${municipality.name} is ${this._getPredictionText(prediction.level)}. De laatste inbraak was ${numberOfDaysSinceLastBurglary} dagen geleden. Ontvang een bericht wanneer het inbraakrisico jouw wijk hoger wordt.`} />
            </Helmet>
        )
    }

    renderPreventionTips(preventionTips, postalCode, prediction) {
        return (
            <section className="section">
                <div className="container">
                    <h2 className="prevention-tip-card-heading">Zo voorkom je inbraken, simpel en effectief</h2>
                    <div className="prevention-tip-card-holder">
                        {preventionTips.map((preventionTip) => (
                            <PreventionTip key={`id:${preventionTip.id}`} preventionTip={preventionTip} postalCode={postalCode} prediction={prediction}/>
                        ))}
                        <Link className="link--arrow gtm-all-tips" to={'/preventietips'}>Bekijk alle inbraakpreventie-tips <ChevronRightIconSVG /></Link>
                    </div>
                </div>
            </section>
        );
    }

    pushActionToDataLayer = (action) => {
        const { postalCode, statistics } = this.state;
        const prediction = ((statistics.prediction && statistics.prediction.level) ? statistics.prediction.level : 0).toString();

        pushToDataLayer({
            event: 'BurglaryToolAction',
            burglaryAction: action,
            burglaryPostalCode: postalCode,
            burglaryPredictionLevel: prediction
        });
    };

    pushManicipalityToDataLayer = (municipality) => {
        const { postalCode, statistics } = this.state;
        const prediction = ((statistics.prediction && statistics.prediction.level) ? statistics.prediction.level : 0).toString();
        pushToDataLayer({
            event: 'BurglaryToolAction',
            burglaryAction: 'BekijkGemeenteVanuitResultaat',
            burglaryManicipality: municipality,
            burglaryPostalCode: postalCode,
            burglaryPredictionLevel: prediction
        });
    };

    // How it works

    showHowItWorks() {
        this.setState({
            showHowItWorks: true
        });

        this.pushActionToDataLayer('HoeWerktHetPopup');
    }

    hideHowItWorks = () => {
        this.setState({
            showHowItWorks: false
        });
    };

    handleClickHowItWorks = (e) => {
        e.preventDefault();
        this.showHowItWorks();
    };

    renderHowItWorksModal = () => {
        if (!this.state.showHowItWorks) {
            return null;
        }

        return (
            <HowItWorksModal onClose={this.hideHowItWorks}/>
        );
    };

    // End how it works

    renderPostalcodeMetadata = () => {
        const { statistics } = this.state;

        if (!statistics.metadata) {
            return;
        }

        const { averageAge, averageNumberOfPersonsPerHousehold } = statistics.metadata;
        let { populationSize } = statistics.metadata;
        const populationSizeMultiplied = new Intl.NumberFormat('nl-NL').format(populationSize * 1000); // eslint-disable-line no-magic-numbers
        let populationLabel = 'inwoners';

        return (
            <React.Fragment>
                <ul className="statistics-list">
                    <StatisticsListItem
                        highlightedText={!populationSizeMultiplied ? '' : populationSizeMultiplied}
                        icon={<PeopleIconSVG className="statistics-list__icon" />}
                        label={populationLabel}
                        helpText="Het aantal inwoners in een wijk wordt gebruikt om een goede voorspelling te kunnen maken van het risico."
                    />
                    <StatisticsListItem
                        highlightedText={!averageNumberOfPersonsPerHousehold ? '' : averageNumberOfPersonsPerHousehold}
                        icon={<HouseIconSVG className="statistics-list__icon" />}
                        label="personen gemiddeld per huishouden"
                        helpText="Hoe meer personen per huishouden, hoe kleiner de kans op een woninginbraak."
                    />
                    <StatisticsListItem
                        highlightedText={!averageAge ? '' : averageAge}
                        icon={<CakeIconSVG className="statistics-list__icon" />}
                        label="jaar is de gemiddelde leeftijd van inwoners"
                        helpText="Er vinden meer inbraken plaats in wijken met een bepaalde leeftijdsverdeling (bijvoorbeeld wijken met veel mensen ouder dan 85 jaar)."
                    />
                </ul>
            </React.Fragment>
        );
    };

    _getPredictionText = (predictionLevel) => {
        const predictionLevelTexts = {
            0: 'Onbekend',
            1: 'zeer laag',
            2: 'zeer laag',
            3: 'laag',
            4: 'laag',
            5: 'middelmatig',
            6: 'middelmatig',
            7: 'hoog',
            8: 'hoog',
            9: 'zeer hoog',
            10: 'zeer hoog',
        };

        if (predictionLevel === 0) {
            return predictionLevelTexts[predictionLevel];
        }

        return predictionLevelTexts[predictionLevel];
    };

    _createShareLink = (statistics, postalCode) => {
        if (!this._getPredictionText(this.getPredictionLevel()) && !statistics.municipality) {
            return `https://api.whatsapp.com/send?text=Ben jij je bewust van het inbraakrisico in jouw wijk? Bekijk https://inbraakbarometer.nl/?whatsapp-share`;
        }

        return `https://api.whatsapp.com/send?text=Ik heb een ${this._getPredictionText(this.getPredictionLevel())} inbraakrisico (postcode ${postalCode} in de gemeente ${statistics.municipality.name}). Ben jij je bewust van het inbraakrisico in jouw wijk? Bekijk https://inbraakbarometer.nl/?whatsapp-share`;
    };

    renderIntro() {
        const { statistics, postalCode } = this.state;

        return (
            <section className="section section--odometer">
                <div className="container">
                    <div className="two-col">
                        <div className="two-col__item">
                            {this.renderIntroOdometer()}
                            <a href={this._createShareLink(statistics, postalCode)} data-action="share/whatsapp/share" className="two-col__share gtm-whatsapp-share">
                                <WhatsAppLogo className="two-col__share-icon"/> Delen via WhatsApp
                            </a>
                        </div>
                        <div className="two-col__item">
                            <h1 className="two-col__title">
                                {content.statistics.main.title}
                                <Link to={'/'} className="link-color" onClick={this.pushActionToDataLayer.bind(this, 'PostcodeWijzigen')}> {postalCode}</Link>
                                {this.renderMunicipality(statistics)}
                            </h1>
                            <button className="how-it-works-link" onClick={this.handleClickHowItWorks}><InfoIcon className="how-it-works-link__icon"/> {content.statistics.odometer.howItWorks}</button>
                            <ul className="statistics-blocklist" data-prediction={this.getPredictionLevel()}>
                                <StatisticsBlockListItem
                                    highlightedText={!statistics.numberOfBurglaries ? '-' : statistics.numberOfBurglaries}
                                    label={content.statistics.main.blocks.total}
                                />
                                <StatisticsBlockListItem
                                    highlightedText={!statistics.numberOfBurglaryAttempts ? '-' : statistics.numberOfBurglaryAttempts}
                                    label={content.statistics.main.blocks.attempts}
                                />
                                <StatisticsBlockListItem
                                    highlightedText={!statistics.numberOfDaysSinceLastBurglary ? '-' : statistics.numberOfDaysSinceLastBurglary}
                                    label="dagen sinds de laatste inbraak"
                                    helpText="Hoe minder dagen geleden, hoe groter de kans op een nieuwe inbraak."
                                />
                            </ul>
                            <div className="risk-alert-banner">
                                <div className="risk-alert-banner__heading-holder">
                                    <BellIconSVG className="risk-alert-banner__icon"/>
                                    <h3 className="risk-alert-banner__heading">Ontvang een inbraak-alert</h3>
                                </div>
                                <p>Al 5993 mensen krijgen een bericht wanneer het inbraakrisico in hun wijk hoger wordt. Meld je aan, zodat ook jij altijd op de hoogte blijft van het risico in jouw buurt.</p>
                                <button type="button" onClick={this.onClickShowSignupForNotifications} className="button button--small">Ik wil op de hoogte blijven</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    renderIntroOdometer() {
        const { postalCode, isFetching } = this.state;
        const odometerSvgSrc = OdometerSVG.replace(/{{date}}/, moment().format('D MMMM'));
        const classes = classnames('odometer', {
            'is-loading': isFetching
        });

        if ( postalCode ) {
            return (
                <button type="button" className="odometer-outer" onClick={this.handleClickHowItWorks} ref={this.odometerRef}>
                    <InlineSVG src={odometerSvgSrc} className={classes} data-prediction={this.getPredictionLevel()}/>
                    <span className="odometer-outer__tooltip">{content.statistics.odometer.tooltip}</span>
                </button>
            );
        }

        return (
            <div className="odometer-outer" ref={this.odometerRef}>
                <InlineSVG src={odometerSvgSrc} className={classes} data-prediction={this.getPredictionLevel()}/>
            </div>
        );
    }

    renderStatisticHistory() {
        const { statistics, postalCode } = this.state;

        if (!statistics.surroundingPredictions || statistics.surroundingPredictions.length < 1) {
            return;
        }

        const days = [];
        for (const date in statistics.surroundingPredictions) {
            const predictionLevel = statistics.surroundingPredictions[date];
            days.push({
                date,
                predictionLevel
            });
        }

        return (
            <section className="section">
                <div className="container">
                    <div className="result-more-info">
                        <div className="result-more-info__item">
                            <h3 className="result-more-info__heading result-more-info__heading--barchart">5 daagse verwachting</h3>
                            <PredictionHistoryBarchart days={days} />
                        </div>
                        <div className="result-more-info__item">
                            <h3 className="result-more-info__heading">Over postcodegebied {postalCode}</h3>
                            {!statistics.municipality.name ?
                                <p>De voorspelling van het inbraakrisico is gebaseerd op informatie over eerdere inbraken en pogingen tot inbraak. En op verschillende andere eigenschappen. Voor deze wijk in geldt bijvoorbeeld:</p>
                                :
                                <p>De voorspelling van het inbraakrisico is gebaseerd op informatie over eerdere inbraken en pogingen tot inbraak. En op verschillende andere eigenschappen. Voor deze wijk in {statistics.municipality.name} geldt bijvoorbeeld:</p>
                            }
                            {this.renderPostalcodeMetadata()}
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    renderSignupForNotificationsModal = () => {
        if (!this.state.showSignupForNotifications) {
            return null;
        }

        return (
            <SignupForNotifications onClose={this.hideSignupForNotifications} onSuccessCallback={this.signedUpForNotifications} initialSignupData={{postalCode: this.state.postalCode}}/>
        );
    };

    renderMunicipality(statistics) {
        if (!statistics.municipality) {
            return;
        }

        return <>
            <br /><span className="two-col__title-municipality"> in <Link to={'/gemeenten/' + statistics.municipality.slug} onClick={this.pushManicipalityToDataLayer.bind(this, statistics.municipality.name )} className="link-color">{statistics.municipality.name}</Link></span>
        </>;
    }
}

export default withRouter(connect()(PredictionResultViewA));
