import FHCookieGuard from '@freshheads/cookie-guard';

export default class CookieGuard {
    constructor() {
        new FHCookieGuard('.js-cookie-alert', {
            callbacks: {
                onCloseCookieAlert: this._reload.bind(this),
                onRevoke: this._reload.bind(this)
            }
        });
    }

    _reload() {
        window.location.reload();
    }
}
