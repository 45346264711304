import React, { useContext, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import skg1image from '../../../../images/skg1.png';
import skg2image from '../../../../images/skg2.png';
import skg3image from '../../../../images/skg3.png';
import LockToolFooter from './footer';
import QuestionOption from './question-option';
import { LockToolContext } from '../../context/lockToolContext';

const LockToolQuestionStarsOnCylinder = ({ history }) => {
    const lockToolFormController = useContext(LockToolContext);
    const { register, handleSubmit, watch } = useForm();
    const watchStarsOnCylinder = watch('starsOnCylinder');

    useEffect(() => {
        if (
            lockToolFormController.starsOnLock === null ||
            lockToolFormController.cylinderInstallationType === null ||
            lockToolFormController.cylinderInstallation === null
        ) {
            history.push('./');
        }
    }, []);

    const onSubmit = (data) => {
        lockToolFormController.setStarsOnCylinder(data.starsOnCylinder)
        history.push('./stap5');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="locktool">
            <div className="locktool__content">
                <div className="container">
                    <div className="locktool__header">
                        <div className="locktool__meta">
                            <div className="locktool__count">4/5</div>
                        </div>
                        <h1 className="locktool__title">
                            Hoeveel SKG sterren staan er op de cilinder?
                        </h1>
                        <div className="locktool__more-info">
                            Het aantal SKG sterren vind je op de cilinder, onder
                            het gat voor de sleutel. Je kunt het herkennen aan
                            onderstaande logo’s.
                        </div>
                    </div>

                    <div className="question-group">
                        <QuestionOption
                            name="starsOnCylinder"
                            value="0"
                            title="Geen ster"
                            register={register}
                        />
                        <QuestionOption
                            name="starsOnCylinder"
                            value="1"
                            title="1 ster"
                            img={skg1image}
                            register={register}
                        />
                        <QuestionOption
                            name="starsOnCylinder"
                            value="2"
                            title="2 sterren"
                            img={skg2image}
                            register={register}
                        />
                        <QuestionOption
                            name="starsOnCylinder"
                            value="3"
                            title="3 sterren"
                            img={skg3image}
                            register={register}
                        />
                    </div>
                </div>
            </div>
            <LockToolFooter>
                <Link to="./stap3">Vorige</Link>
                <input
                    className="button"
                    type="submit"
                    value="Volgende"
                    disabled={!watchStarsOnCylinder}
                />
            </LockToolFooter>
        </form>
    );
};

LockToolQuestionStarsOnCylinder.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(LockToolQuestionStarsOnCylinder);
