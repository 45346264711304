import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Tips extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            icon: PropTypes.node,
            title: PropTypes.string,
            subtitle: PropTypes.string,
            description: PropTypes.string,
            content: PropTypes.oneOfType([
                PropTypes.arrayOf(PropTypes.node),
                PropTypes.node
            ])
        })),
        className: PropTypes.string,
        mini: PropTypes.bool
    };

    render() {
        const { items, mini: isMini, className } = this.props;
        const classes = classnames('tips', {
            'tips--mini': isMini
        }, className);

        return (
            <ul className={classes}>
                {items.map((tip, i) => (
                    <li className="tip" key={i}>
                        {tip.icon}
                        <div className="tip__content">
                            {this.renderTitle(tip)}
                            {this.renderSubtitle(tip)}
                            {this.renderDescription(tip)}
                            {this.renderContent(tip)}
                        </div>
                    </li>
                ))}
            </ul>
        );
    }

    renderTitle(tip) {
        if ( typeof tip.title === 'undefined' ) {
            return null;
        }

        const classes = classnames('tip__title', {
            [`text-gradient text-gradient--${tip.gradient}`]: tip.gradient
        });

        return <h2 className={classes}>{tip.title}</h2>;
    }

    renderSubtitle(tip) {
        if ( typeof tip.subtitle === 'undefined' ) {
            return null;
        }

        const classes = classnames('tip__subtitle', {
            [`text-gradient text-gradient--${tip.gradient}`]: tip.gradient
        });

        return <h3 className={classes}>{tip.subtitle}</h3>;
    }

    renderDescription(tip) {
        if ( typeof tip.description === 'undefined' ) {
            return null;
        }

        return <span className="tip__description">{tip.description}</span>;
    }

    renderContent(tip) {
        if ( typeof tip.content === 'undefined' ) {
            return null;
        }

        return tip.content;
    }
}
