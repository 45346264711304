import Modernizr from '../../../.modernizrrc';

Modernizr.addTest('svg-transforms', () => {
    // https://bugzilla.mozilla.org/show_bug.cgi?id=755947
    if ( navigator.userAgent.toLowerCase().includes('firefox') ) {
        return true;
    }

    const styles = ['-moz-', '-ms-', '-webkit-', ''].reduce((css, prefix) => {
        return `${css}${prefix}transform: translateX(10px);`;
    }, '');

    const body = document.querySelector('body');
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

    svg.appendChild(path);
    svg.style.cssText = 'width: 100px; visibility: transparent;';
    body.appendChild(svg);

    const initialBoundingRect = path.getBoundingClientRect();
    path.style.cssText = styles;
    const newBoundingRect = path.getBoundingClientRect();

    body.removeChild(svg);

    return initialBoundingRect.left !== newBoundingRect.left;
});
