/* eslint-disable import/prefer-default-export */

export const isSlimThuisWebview = () => {
    return typeof window.ToolApp !== 'undefined';
};

export const getProfile = () => {
    if ( !isSlimThuisWebview() ) {
        return {};
    }

    return JSON.parse(window.ToolApp.getProfile());
};
