import React from 'react';

import { ReactComponent as PersonsIconSVG } from '../../../images/sprites/persons.svg';
import { ReactComponent as TimeIconSVG } from '../../../images/sprites/time.svg';

export default {
    header: {
        howItWorks: 'Waarop baseren we de voorspelling'
    },
    homeArticle: {
        title: <>Benieuwd naar het<br/> inbraakrisico in jouw wijk?</>,
        text: 'Woninginbraken komen overal voor. De ene wijk is meer in trek bij inbrekers dan de andere. De InbraakBarometer heeft een uniek, zelflerend algoritme. Door dagelijks data te analyseren zoals inbraakfrequentie, seizoenspatronen, leeftijdsopbouw en gezinssamenstelling, geeft de barometer een actuele voorspelling. Zo zie je in één oogopslag hoe groot de kans op een inbraak in jouw wijk is.',
        form: {
            placeholder: '',
            help: 'Vul hier de 4 cijfers van je postcode in',
            button: 'Bekijk risico',
            howItWorks: 'Waarop baseren we de voorspelling?'
        }
    },
    cta: {
        predict: {
            title: 'Benieuwd naar het inbraakrisico in jouw wijk?',
            action: {
                button: 'Bekijk risico',
                tooltip: 'Vul hier de 4 cijfers van je postcode in',
            }
        }
    },
    statistics: {
        main: {
            title: 'Inbraakrisico voor',
            titleLink: 'wijzigen',
            blocks: {
                total: 'inbraken in de laatste 3 maanden',
                attempts: 'pogingen in de laatste 3 maanden',
                day: 'is de dag met de meeste inbraken'
            },
            help: 'Het actuele inbraakrisico is gebaseerd op de inbraken uit het verleden en algemene informatie van de wijk.',
            howItWorks: 'Bekijk alle data waarop dit risico is gebaseerd'
        },
        odometer: {
            tooltip: 'Hoe werkt het?',
            howItWorks: 'Zo wordt het inbraakrisico berekend'
        }
    },
    predictionHistoryBarchart: {
        tooltipTitle: 'Inbraakrisico'
    },
    Advice: {
        title: <>Persoonlijk advies van<br/> de preventie-expert van Interpolis</>,
        info: 'Kom er samen met onze preventie-expert achter hoe inbrekers jouw huis kunnen binnen komen. En krijg tips om jouw huis minder aantrekkelijk te maken om in te breken.',
        button: 'Vraag een check aan',
        image: {
            url: '/assets/frontend/images/icons/advice-rounds.png',
            alt: 'Experts die advies geven'
        }
    },
    signupForNotifications: {
        title: 'Meld je aan voor de inbraaknotificatie',
        description: 'Ik wil graag een seintje via e-mail krijgen als het inbraakrisico in mijn wijk verhoogt.',
        form: {
            label: 'Mijn e-mailadres is',
            placeholder: 'email@adres.nl',
            newsletter: 'Ik wil ook graag de maandelijkse nieuwsbrief ‘Bij ons Thuis’ ontvangen. Met daarbij toegang tot mooie aanbiedingen rondom nieuwe oplossingen en diensten van Interpolis.',
            button: 'Aanmelden',
            privacy: 'Je gegevens worden alleen voor de Inbraak-alert gebruikt. We bewaren je gegevens zolang je aangemeld bent voor de service Inbraak-alert. Zodra je een Inbraak-alert per e-mail van ons ontvangt kan je jezelf via die e-mail afmelden voor de service en ontvang je geen Inbraak-alerts meer.'
        },
        signedUp: 'Bedankt',
        signedUpText: 'Je aanmelding is gelukt. Vanaf nu krijg je een seintje via e-mail als het inbraakrisico in jouw wijk verhoogt.'
    },
    howItWorks: {
        title: 'De voorspelling van het inbraakrisico is gebaseerd op verschillende databronnen.',
        description: 'Politie Nederland en het CBS bieden veel publieke data aan over inbraken en demografische gegevens van woonwijken. Uit deze databronnen filteren we een aantal parameters die invloed hebben op het inbraakrisico. Met een speciaal, zelflerend algoritme bepalen we een actuele voorspelling van het risico in jouw wijk. Hieronder vind je de parameters die we hebben gebruikt en waarom.',
        note: 'Disclaimer: De InbraakBarometer geeft een voorspelling van het inbraakrisico en is daarmee geen exacte weerspiegeling van de realiteit.',
        tips: [{
            icon: <TimeIconSVG className="tip__icon" />,
            subtitle: 'Tijd sinds laatste inbraak',
            text: 'Hoe korter de tijd sinds de laatste inbraak, hoe groter de kans wordt op een nieuwe (poging tot) inbraak.',
            subtitle2: 'Dag en maand van het jaar',
            text2: 'De feestdagen en vakanties zijn populaire periodes voor inbrekers. Daarnaast wordt er op vrijdag en zaterdag ongeveer 15% meer ingebroken.'
        }, {
            icon: <PersonsIconSVG className="tip__icon" />,
            subtitle: 'Gemiddelde grootte huishoudens',
            text: 'In wijken waarin het gemiddelde huishouden uit een klein aantal personen bestaat, wordt meer ingebroken dan in wijken met grote huishoudens.',
            subtitle2: 'Leeftijdsverdeling van de wijk',
            text2: 'Uit onderzoek is gebleken dat er meer inbraken voorkomen in wijken met veel inwoners in de volgende leeftijdscategorieën:',
            items: [
                'Kinderen tussen 5 en 10 jaar',
                'Volwassen tussen 30 en 35 jaar',
                'Senioren tussen 85 en 90 jaar'
            ]
        }]
    }
};
