import React, { useState } from 'react';
import { LockToolContext } from './lockToolContext';
import PropTypes from 'prop-types';

const LockToolContextProvider = ({ children }) => {
    const [cylinderInstallationType, setCylinderInstallationType] = useState(
        null
    );
    const [starsOnLock, setStarsOnLock] = useState(null);
    const [cylinderInstallation, setCylinderInstallation] = useState(null);
    const [starsOnCylinder, setStarsOnCylinder] = useState(null);
    const [screwsOnShield, setScrewsOnShield] = useState(null);

    const resetForm = () => {
        setCylinderInstallationType(null);
        setStarsOnLock(null);
        setCylinderInstallation(null);
        setStarsOnCylinder(null);
        setScrewsOnShield(null);
    };

    const formData = {
        cylinderInstallationType,
        starsOnLock,
        cylinderInstallation,
        starsOnCylinder,
        screwsOnShield,
    };

    return (
        <LockToolContext.Provider
            value={{
                cylinderInstallationType: cylinderInstallationType,
                setCylinderInstallationType: setCylinderInstallationType,
                starsOnLock: starsOnLock,
                setStarsOnLock: setStarsOnLock,
                cylinderInstallation: cylinderInstallation,
                setCylinderInstallation: setCylinderInstallation,
                starsOnCylinder: starsOnCylinder,
                setStarsOnCylinder: setStarsOnCylinder,
                screwsOnShield: screwsOnShield,
                setScrewsOnShield: setScrewsOnShield,
                resetForm: resetForm,
                formData: formData,
            }}
        >
            {children}
        </LockToolContext.Provider>
    );
};

LockToolContextProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
export default LockToolContextProvider;
