import React from 'react';
import PropTypes from 'prop-types';

// Store
import { getStore } from '../store';

export default class ErrorBoundary extends React.Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ])
    };

    state = {
        hasError: false
    };

    componentDidCatch() {
        this.setState({
            hasError: true
        });
    }

    handleClickReset = async (e) => {
        e.preventDefault();
        e.target.blur();

        const { persistor } = getStore();
        await persistor.purge();

        window.location.reload();
    }

    render() {
        const { hasError } = this.state;

        if ( hasError ) {
            return (
                <div className="container">
                    <div className="error">
                        <h2 className="text-gradient text-gradient--secondary">Er is iets mis gegaan</h2>
                        <p>Het lijkt er op dat er iets niet helemaal goed is gegaan. Probeer de tool opnieuw op te starten, als dat niet werkt kun je de tool hieronder ook resetten.</p>
                        <button type="button" className="button button--link" onClick={this.handleClickReset}>Reset en herlaad tool</button>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}
