import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class CallToAction extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        type: PropTypes.oneOf(['primary']),
        flatten: PropTypes.bool,
        context: PropTypes.oneOf(['card']),
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ])
    };

    render() {
        const { className, type, context, children, flatten: isFlatten } = this.props;
        const classes = classnames('cta', {
            [`cta--${type}`]: !!type,
            [`cta--${context}`]: !!context,
            'cta--flatten': isFlatten
        }, className);

        return (
            <section className={classes}>
                <div className="cta__content">
                    {children}
                </div>
            </section>
        );
    }
}
