import 'core-js/stable';
import 'regenerator-runtime/runtime';
import "isomorphic-fetch"
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router';
import smoothscroll from 'smoothscroll-polyfill';
import Helmet from 'react-helmet';

// Core
import App from './core-app';

// Views
import PredictionResultView from './views/prediction-result';
import MunicipalityOverviewView from './views/municipality-overview';
import PreventionTipOverviewView from './views/prevention-tip-overview';
import MunicipalityDetailView from './views/municipality-detail';
import LockToolView from './views/lock-tool';
import CookiesView from './views/cookies';

// Polyfills
smoothscroll.polyfill();

// Helpers
import './helpers/modernizr';

// Modules
import CookieGuard from './modules/cookie-guard';
import {isSlimThuisWebview} from "./core-helpers/content";

new CookieGuard();

const reactApp = document.querySelector('.app');
if (reactApp) {
    ReactDOM.render(
        <App id="burglaries" basename="" className="app app--responsive">
            <Helmet>
                <title>Slotentool</title>
                <meta property="og:title" content="Inbraakbarometer" />
                <meta
                    name="description"
                    content="Bekijk het inbraakrisico van de postcode waar jij woont en ontvang een inbraak-alert wanneer de kans op inbraak in jouw wijk hoger wordt."
                />
                <meta
                    property="og:description"
                    content="Bekijk het inbraakrisico van de postcode waar jij woont en ontvang een inbraak-alert wanneer de kans op inbraak in jouw wijk hoger wordt."
                />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <Switch>
                <Route exact path="/" component={LockToolView} />
                <Route
                    exact
                    path="/resultaat"
                    component={PredictionResultView}
                />
                <Route
                    useParams
                    path="/gemeenten/:municipalityCode"
                    component={MunicipalityDetailView}
                />
                <Route
                    exact
                    path="/gemeenten"
                    component={MunicipalityOverviewView}
                />
                <Route
                    exact
                    path="/preventietips"
                    component={PreventionTipOverviewView}
                />
                <Route path="/slotentool" component={LockToolView} />
                <Route path="/cookies" component={CookiesView} />
            </Switch>
        </App>,
        reactApp
    );
}

if (isSlimThuisWebview()) {
    // remove target from anchors in app webview
    [].forEach.call(document.querySelectorAll("a[target='_blank']"), function(a) {
        a.removeAttribute('target');
    });
}
