import React, { useEffect } from 'react';
import {
    withRouter,
    useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import LockToolQuestionCylinderInstallationType from './question-cylinder-installation-type';
import LockToolQuestionStarsOnLock from './question-stars-on-lock';
import LockToolQuestionCylinderInstallation from './question-cylinder-installation';
import LockToolQuestionStarsOnCylinder from './question-stars-on-cylinder';
import LockToolQuestionScrewsOnShield from './question-screws-on-shield';

const LockToolSteps = ({history}) => {
    const steps = ['stap1', 'stap2', 'stap3', 'stap4', 'stap5'];
    const { step } = useParams();

    useEffect(() => {
        if (!steps.includes(step)) {
            history.push('/slotentool');
        };
    }, [step]);

    const renderStep = (step) => {
        switch (step) {
            case steps[0]:
                return <LockToolQuestionCylinderInstallationType />;
            case steps[1]:
                return <LockToolQuestionStarsOnLock />;
            case steps[2]:
                return <LockToolQuestionCylinderInstallation />;
            case steps[3]:
                return <LockToolQuestionStarsOnCylinder />;
            case steps[4]:
                return <LockToolQuestionScrewsOnShield />;
            default:
                return <></>;
        };
    }

    return (
        <>
            {renderStep(step)}
        </>
    );
}

LockToolSteps.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(LockToolSteps);
