import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Footer extends React.Component {
    static propTypes = {
        flatten: PropTypes.bool,
        showSeperator: PropTypes.bool,
        className: PropTypes.string
    };

    static defaultProps = {
        showSeperator: true
    };

    renderSeperator = () => {
        const { showSeperator } = this.props;

        if (!showSeperator) {
            return;
        }

        return <hr className="seperator"/>;
    };

    render() {
        const { className, flatten: isFlatten } = this.props;
        const classes = classnames('footer', {
            'footer--flatten': isFlatten
        }, className);

        return (
            <footer className={classes}>
                <div className="container">
                    {this.renderSeperator()}
                    <div className="footer__content">
                        <div className="footer__text">
                            Slotentool is een product van{' '}
                            <strong>Interpolis</strong>
                        </div>
                        <div className="footer__menu">
                            <a href={'https://www.interpolis.nl/privacy-statement'} target={"_blank"}
                               rel="noopener noreferrer">
                                Privacy
                            </a>
                            <a href={'https://www.interpolis.nl/cookie-statement'} target={"_blank"}
                               rel="noopener noreferrer">
                                Cookies
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
