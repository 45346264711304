import React from 'react';
import PropTypes from 'prop-types';

function QuestionOption(props) {
    const { name, value, title, img, register } = props;
    return (
        <div className="question__wrapper">
            <input
                name={name}
                id={value}
                type="radio"
                value={value}
                ref={register}
            />
            <label htmlFor={value} className="question">
                <div className="question-label__title">{title}</div>
                {img && (
                    <div className="question-label__image">
                        <img src={img} alt="" />
                    </div>
                )}
            </label>
        </div>
    );
}

QuestionOption.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    img: PropTypes.string,
    register: PropTypes.any.isRequired,
};

export default QuestionOption;
