import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import posed from 'react-pose';
import Mousetrap from 'mousetrap';

// SVGs
import { ReactComponent as CloseIconSVG } from '../../../images/sprites/close.svg';


// Constants
const MODAL_OPEN_CLASS = 'modal-open';
const MODAL_CONTENT_CLASS = 'modal__content';
const MODAL_STOP_PROPAGATION_EVENTS = ['touchmove', 'scroll', 'mousewheel'];

// Posed
const PosedBackdrop = posed.button({
    enter: {
        opacity: 1,
        transition: {
            duration: 200
        }
    },
    exit: {
        opacity: 0.5
    }
});

const PosedContent = posed.div({
    enter: {
        opacity: 1,
        y: 0
    },
    exit: {
        opacity: 0.5,
        y: 50
    }
});

export default class Modal extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]),
        onClose: PropTypes.func
    };

    contentWrapperRef = React.createRef();

    componentDidMount() {
        document.documentElement.classList.add(MODAL_OPEN_CLASS);

        this.contentWrapperRef.current.addEventListener('click', this.handleClickContentWrapper);
        MODAL_STOP_PROPAGATION_EVENTS.forEach((event) => {
            this.contentWrapperRef.current.addEventListener(event, this.handlePropagation);
        });

        this.contentWrapperRef.current.focus();
        Mousetrap.bind('esc', this.close);
    }

    componentWillUnmount() {
        document.documentElement.classList.remove(MODAL_OPEN_CLASS);

        this.contentWrapperRef.current.removeEventListener('click', this.handleClickContentWrapper);
        MODAL_STOP_PROPAGATION_EVENTS.forEach((event) => {
            this.contentWrapperRef.current.removeEventListener(event, this.handlePropagation);
        });

        Mousetrap.unbind('esc');
    }

    handlePropagation = (e) => {
        e.stopPropagation();
        e.stopImmediatePropagation();
    };

    handleClickBackdrop = (e) => {
        this.close(e);
    };

    handleClickClose = (e) => {
        this.close(e);
    };

    handleClickContentWrapper = (e) => {
        if ( e.target.closest(`.${MODAL_CONTENT_CLASS}`) !== null ) {
            return;
        }

        this.handleClickBackdrop(e);
    };

    close = (e) => {
        const { onClose } = this.props;

        if ( typeof onClose === 'function' ) {
            onClose(e);
        }
    };

    render() {
        const { children, className } = this.props;
        const classes = classnames('modal', className);

        return (
            <section className={classes}>
                <PosedBackdrop type="button" className="modal__backdrop" onClick={this.handleClickBackdrop} initialPose="exit" pose="enter"/>
                <div className="modal__content-wrapper" ref={this.contentWrapperRef} tabIndex="-1">
                    <PosedContent className={MODAL_CONTENT_CLASS} initialPose="exit" pose="enter">
                        <button className="modal__content__close" onClick={this.handleClickClose}>
                            <CloseIconSVG />
                        </button>
                        {children}
                    </PosedContent>
                </div>
            </section>
        );
    }
}
