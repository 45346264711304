import posed from 'react-pose';

// Constants
export const TRANSITION_DURATION = 200;

// Functions
const getDuration = (state = {}) => {
    return state.transition === false ? 0 : TRANSITION_DURATION;
};

export default posed.div({
    enter: {
        opacity: 1,
        delay: (props) => {
            return getDuration(props.location.state);
        },
        transition: (props) => ({
            duration: getDuration(props.location.state)
        })
    },
    exit: {
        opacity: 0,
        transition: (props) => ({
            duration: getDuration(props.location.state)
        })
    }
});
