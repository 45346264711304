import React, { useEffect, useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { LockToolContext } from '../../context/lockToolContext';

function LockToolIntro() {
    const lockToolFormController = useContext(LockToolContext);

    useEffect(() => {
        lockToolFormController.resetForm;
    }, []);

    return (
        <>
            <section className="intro intro--locktool">
                <div
                    className="intro__image"
                    style={{
                        backgroundImage:
                            "url('/assets/frontend/images/goed-zicht-op-de-voordeur.jpg')",
                    }}
                />
                <div className="container">
                    <div className="intro__postalcode-block">
                        <h1>
                            3 op de 5 huizen heeft een onveilig voordeurslot
                        </h1>
                        <label className="prediction-form__label">
                            Een onveilig voordeurslot kan door een inbreker
                            binnen 3 minuten worden opengemaakt. Goede sloten
                            zorgen ervoor dat de inbreker flinke vertraging
                            oploopt. Kies dus voor een veilig slot op je
                            voordeur. Dan is de kans groot dat een inbreker jouw
                            huis overslaat.
                        </label>
                        <Link className="button" to="/slotentool/stap1">
                            Hoe veilig is jouw slot?
                        </Link>
                        <div className="prediction-form__help">
                            <small>
                                <em>
                                    Ontdek in 2 minuten hoe jij jouw voordeur
                                    veiliger maakt. De gegevens die je invult
                                    slaan wij niet op.
                                </em>
                            </small>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default withRouter(LockToolIntro);
