import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';

const CookiesView = () => {
    return (
        <>
            <section className="section">
                <div className="container container--small">
                    <div className="content--container">
                        <h1>Cookie statement</h1>
                        <p>
                            Interpolis maakt op deze website gebruik van
                            cookies. Een cookie is een klein stukje informatie
                            dat in de vorm van een bestand op uw computer of
                            mobiele apparaat wordt opgeslagen. Hierdoor
                            herkennen wij uw computer of mobiele apparaat bij
                            een volgend bezoek. Als we over cookies spreken
                            bedoelen we ook technieken die vergelijkbaar zijn
                            met cookies. Bijvoorbeeld JavaScript, tags, web
                            beacons, pixels en HTML5 Local Storage. Op deze
                            pagina leest u welke cookies wij plaatsen. En wat
                            dat voor uw privacy betekent.
                        </p>
                        <h2>Wij plaatsen verschillende soorten cookies</h2>
                        <p>
                            <strong>
                                Functionele cookies
                                <br />
                            </strong>
                            Deze cookies zorgen er bijvoorbeeld voor dat de
                            website technisch goed werkt en/of beveiligd
                            is.&nbsp;
                        </p>
                        <ul>
                            <li>Cookie: Interpolis</li>
                            <li>Doel: veilige correcte werking van de site</li>
                            <li>Opgeslagen gegevens: n.v.t.</li>
                            <li>Geldigheid cookies: 2 jaar</li>
                            <li>Bewaartermijn: n.v.t.</li>
                            <li>
                                Gegevens worden buiten de EER opgeslagen? n.v.t.
                            </li>
                        </ul>
                        <ul>
                            <li>Cookie: Google Tag Manager</li>
                            <li>
                                Doel: het inladen van alle cookies en permissies
                            </li>
                            <li>
                                Opgeslagen gegevens: registratie en permissies
                                van alle cookies afgegeven door de bezoekers
                                (GTM is slechts doorgeefluik van andere cookies,
                                slaat geen informatie op)
                            </li>
                            <li>Geldigheid cookies: 2 jaar</li>
                            <li>Bewaartermijn: n.v.t.</li>
                            <li>
                                Gegevens worden buiten de EER opgeslagen? n.v.t.
                            </li>
                        </ul>
                        <p>
                            <strong>
                                Analytische cookies
                                <br />
                            </strong>
                            Deze cookies gebruiken wij om statistieken te
                            verzamelen over bijvoorbeeld het gebruik van onze
                            website. En om onze website te verbeteren en meer
                            gebruiksvriendelijk te maken
                        </p>
                        <ul>
                            <li>Cookie: Google Analytics</li>
                            <li>
                                <strong></strong>Doel: Het vastleggen van het
                                surfgedrag van de bezoekers van Interpolis.nl.
                                Deze informatie gebruikt Interpolis om de
                                gebruiksvriendelijkheid van de website en onze
                                klantenservice te verbeteren
                            </li>
                            <li>
                                Opgeslagen gegevens: Anonieme webstatistieken.
                                Random gegenereerd userID.{' '}
                                <a href="https://policies.google.com/privacy?hl=nl">
                                    Privacystatement Google
                                </a>
                            </li>
                            <li>
                                <a href="https://policies.google.com/privacy?hl=nl"></a>
                                Geldigheid cookies: 2 jaar
                            </li>
                            <li>Bewaartermijn: 26 maanden</li>
                            <li>
                                Gegevens worden buiten de EER opgeslagen? Ja
                            </li>
                        </ul>

                        <p>
                            Voor het gebruik van deze cookie heeft Interpolis
                            een verwerkersovereenkomst met Google gesloten. Een
                            deel van het IP-adres wordt gemaskeerd en ‘gegevens
                            delen’ is uitgezet.&nbsp;
                        </p>
                        <ul>
                            <li>Cookie:&nbsp;Google Optimize</li>
                            <li>
                                Doel: Het uitvoeren van experimenten (A/B
                                testen) en het vastleggen van het klikgedrag van
                                de bezoekers van onze website. Deze informatie
                                gebruiken we om de gebruiksvriendelijkheid van
                                de website en onze klantenservice te verbeteren.
                            </li>
                            <li>
                                Opgeslagen gegevens: Anonieme webstatistieken
                                over uitgevoerde experimenten (zoals de pagina
                                waarop het experiment geactiveerd werd en de
                                pagina daarvoor). Random gegenereerd userID.
                                Websitegedrag binnen de experimenten wordt
                                gekoppeld aan gegevens in Google Analytics.
                                <a href="https://policies.google.com/privacy?hl=nl">
                                    {' '}
                                    Privacystatement Google
                                </a>
                            </li>
                            <li>Geldigheid cookies: 100 dagen</li>
                            <li>Bewaartermijn: 26 maanden</li>
                            <li>
                                Gegevens worden buiten de EER opgeslagen? Ja
                            </li>
                        </ul>

                        <ul>
                            <li>Cookie: Hotjar</li>
                            <li>
                                Doel: Het vastleggen van het
                                klikgedrag/websitegedrag van de bezoekers van
                                onze website en het doen van formulier analyses.
                                Ook kunnen bezoekers feedback achterlaten over
                                het gebruik van de website. Deze informatie
                                gebruiken we om de gebruiksvriendelijkheid van
                                de website en onze klantenservice te verbeteren.{' '}
                            </li>
                            <li>
                                Opgeslagen gegevens: Anonieme web statistieken,
                                met behulp van heat- en scrollmaps,
                                schermopnames, random genereerd gebruikers ID.
                                Feedback gegevens welke een klant of bezoeker
                                zelf heeft achtergelaten op onze website.{' '}
                                <a href="https://www.hotjar.com/legal/policies/privacy/">
                                    Privacy statement van Hotjar
                                </a>
                            </li>
                            <li>Geldigheid cookies: 1 jaar</li>
                            <li>Bewaartermijn: 1 jaar</li>
                            <li>
                                Gegevens worden buiten de EER opgeslagen? Nee
                            </li>
                        </ul>

                        <h2>Handig om te weten over dit Cookie Statement</h2>
                        <p>
                            Interpolis mag dit Cookie Statement veranderen als
                            er ontwikkelingen zijn in het bedrijf. En als er
                            iets verandert in de wetten of regels. Dit Cookie
                            Statement is voor het laatst veranderd op 8 maart
                            2021. Op onze site vindt u altijd de laatste versie.
                            Kijk daarom regelmatig op onze site.
                        </p>
                        <p>
                            Interpolis is een onderdeel van Achmea groep. Achmea
                            B.V. is, binnen de Achmea groep, verantwoordelijk
                            voor uw privacy.
                        </p>
                    </div>
                </div>
            </section>
            <Footer flatten />
        </>
    );
};

export default CookiesView;
