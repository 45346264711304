import React from 'react';
import skgInsteekSlotImage from '../../../../../images/locktool/skg-insteekslot-popup.jpg';

// Components
import Modal from '../../../core-components/modal';

import PropTypes from 'prop-types';

const StarsOnLockModal = ({ onClose }) => {
    return (
        <Modal onClose={onClose}>
            <h2>Hoeveel SKG sterren heeft mijn slot?</h2>
            <h3>Oplegslot</h3>
            <p>
                Het aantal SKG sterren bij een oplegslot staat vaak op de
                slotkast die bevestigd is aan de binnenkant van de deur. Als er
                geen SKG logo te vinden is, dan heb je waarschijnlijk geen SKG
                goedgekeurd slot. Het kan zijn dat er een SKG logo staat op het
                metalen onderdeel waar je de sleutel in stopt. Dit is de
                cilinder. Deze SKG sterren gelden alleen voor de cilinder, niet
                voor het slot.
            </p>
            <h3>Insteekslot of meerpuntssluiting</h3>
            <p>
                Bij deze sloten vind je het aantal SKG sterren meestal aan de
                zijkant van de deur. Op de afdekplaat, daar waar het slot in de
                deur gaat.
            </p>
            <img src={skgInsteekSlotImage} alt="Insteekslot" />
        </Modal>
    );
};


StarsOnLockModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default StarsOnLockModal;
