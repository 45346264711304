import React from 'react';
// Content
import content from '../content';

// Components
import Modal from '../core-components/modal';
import Tips from '../core-components/tips';
import PropTypes from 'prop-types';

class HowItWorksModal extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired
    };

    _renderItems = (tip) => {
        if (tip.items) {
            return (
                <ul>
                    {tip.items.map((item, i) => (
                        <li key={i}>{item}</li>
                    ))}
                </ul>
            );
        }
    };

    render() {
        const { onClose } = this.props;
        const tips = content.howItWorks.tips.map((tip) => {
            return Object.assign({}, tip, {
                content: (
                    <React.Fragment>
                        <p>{tip.text}</p>
                        <h3 className="tip__subtitle">{tip.subtitle2}</h3>
                        <p>{tip.text2}</p>
                        {this._renderItems(tip)}
                    </React.Fragment>
                )
            });
        });

        return (
            <Modal onClose={onClose}>
                <h2>{content.howItWorks.title}</h2>
                <Tips items={tips}/>
                <p className="tips__disclaimer">{content.howItWorks.note}</p>
            </Modal>
        );
    }
}

export default HowItWorksModal;
