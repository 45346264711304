import React, { useContext, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import schroevenImage from '../../../../images/locktool/schroeven.jpg';
import geenSchroevenImage from '../../../../images/locktool/geen-schroeven.jpg';
import LockToolFooter from './footer';
import QuestionOption from './question-option';
import { LockToolContext } from '../../context/lockToolContext';

const LockToolQuestionScrewsOnShield = ({ history }) => {
    const lockToolFormController = useContext(LockToolContext);
    const { register, handleSubmit, watch } = useForm();
    const watchScrewsOnShield = watch('screwsOnShield');

    useEffect(() => {
        if (
            lockToolFormController.starsOnLock === null ||
            lockToolFormController.cylinderInstallationType === null ||
            lockToolFormController.cylinderInstallation === null ||
            lockToolFormController.starsOnCylinder === null
        ) {
            history.push('./');
        }
    }, []);

    useEffect(() => {
        if (lockToolFormController.screwsOnShield === null) {
            return;
        }
        history.push({
            pathname: '/slotentool/resultaat',
            search: Object.entries(lockToolFormController.formData).map(([key, val]) => `${key}=${val}`).join('&'),
        });
    }, [lockToolFormController.formData]);

    const onSubmit = async (data) => {
        lockToolFormController.setScrewsOnShield(data.screwsOnShield);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="locktool">
            <div className="locktool__content">
                <div className="container">
                    <div className="locktool__header">
                        <div className="locktool__meta">
                            <div className="locktool__count">5/5</div>
                        </div>
                        <h1 className="locktool__title">
                            Zijn er op de schildplaat aan de buitenkant van de
                            deur schoeven zichtbaar?
                        </h1>
                    </div>

                    <div className="question-group">
                        <QuestionOption
                            name="screwsOnShield"
                            value="1"
                            title="Ja"
                            img={schroevenImage}
                            register={register}
                        />
                        <QuestionOption
                            name="screwsOnShield"
                            value="0"
                            title="Nee"
                            img={geenSchroevenImage}
                            register={register}
                        />
                    </div>
                </div>
            </div>
            <LockToolFooter>
                <Link to="./stap4">Vorige</Link>
                <input
                    className="button"
                    type="submit"
                    value="Volgende"
                    disabled={!watchScrewsOnShield}
                />
            </LockToolFooter>
        </form>
    );
};

LockToolQuestionScrewsOnShield.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(LockToolQuestionScrewsOnShield);
