import { createStore, compose, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Constants & variables
const DUMMY_REDUCER_ID = 'dummy';
let IDENTIFIER, BASENAME, history, store, persistor;

export default (id, basename, reducers = {}) => {
    IDENTIFIER = `tools:${id}`;
    BASENAME = `${APP_BASENAME}${basename}`;

    if ( !Object.values(reducers).length ) {
        reducers = {
            [DUMMY_REDUCER_ID]: (state = null) => state
        };
    }

    history = createBrowserHistory({
        basename: BASENAME
    });

    const createRootReducer = (history) => {
        return persistCombineReducers({
            key: IDENTIFIER,
            storage: storage,
            whitelist: Object.keys(reducers).filter((id) => id !== DUMMY_REDUCER_ID)
        }, Object.assign({
            router: connectRouter(history),
        }, reducers));
    };

    store = createStore(
        createRootReducer(history),
        compose(applyMiddleware(routerMiddleware(history)))
    );
    persistor = persistStore(store);

    return {
        IDENTIFIER,
        BASENAME,
        history,
        store,
        persistor
    };
};

export const getStore = () => {
    if ( [IDENTIFIER, history, store, persistor].some((item) => typeof item === 'undefined') ) {
        throw new Error('Store was not created yet');
    }

    return {
        IDENTIFIER,
        BASENAME,
        history,
        store,
        persistor
    };
};
