import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import oplegSlotImage from '../../../../images/locktool/oplegslot.jpg';
import insteekSlotImage from '../../../../images/locktool/insteekslot.jpg';
import meerpuntssluitingImage from '../../../../images/locktool/meerpuntsluiting.jpg';
import LockToolFooter from './footer';
import QuestionOption from './question-option';
import CylinderInstallationTypeModal from './modals/cylinder-installation-type-modal';
import { LockToolContext } from '../../context/lockToolContext';
import pushToDataLayer from '../../core-helpers/gtm';

const LockToolQuestionCylinderInstallationType = ({ history }) => {
    const lockToolFormController = useContext(LockToolContext);

    const { register, handleSubmit, watch } = useForm();
    const [modalOpen, setModalOpen] = useState(false);
    const watchCylinderInstallationType = watch('cylinderInstallationType');
    const onSubmit = (data) => {
        const value = '' + data.cylinderInstallationType;
        lockToolFormController.setCylinderInstallationType(value);
        history.push('./stap2');
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="locktool">
            <div className="locktool__content">
                <div className="container">
                    <div className="locktool__header">
                        <div className="locktool__meta">
                            <div className="locktool__count">1/5</div>
                        </div>
                        <h1 className="locktool__title">
                            Wat voor soort slot heeft jouw voordeur?
                        </h1>
                        <div className="locktool__more-info">
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    pushToDataLayer({
                                        event: 'LockToolAction',
                                        lockToolAction:
                                            'open modal cylinder installation type',
                                    });
                                    setModalOpen(true);
                                }}
                            >
                                Ik heb meer hulp nodig
                            </a>
                        </div>
                    </div>

                    <div className="question-group">
                        <QuestionOption
                            name="cylinderInstallationType"
                            value="rim"
                            title="Oplegslot"
                            img={oplegSlotImage}
                            register={register}
                        />
                        <QuestionOption
                            name="cylinderInstallationType"
                            value="mortise"
                            title="Insteekslot"
                            img={insteekSlotImage}
                            register={register}
                        />
                        <QuestionOption
                            name="cylinderInstallationType"
                            value="three-point"
                            title="Meerpuntssluiting"
                            img={meerpuntssluitingImage}
                            register={register}
                        />
                    </div>
                </div>
            </div>
            {modalOpen && (
                <CylinderInstallationTypeModal
                    onClose={() => setModalOpen(false)}
                />
            )}
            <LockToolFooter>
                <input
                    className="button"
                    type="submit"
                    value="Volgende"
                    disabled={!watchCylinderInstallationType}
                />
            </LockToolFooter>
        </form>
    );
};

LockToolQuestionCylinderInstallationType.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(LockToolQuestionCylinderInstallationType);
