import React, { useContext, useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import skg1image from '../../../../images/skg1.png';
import skg2image from '../../../../images/skg2.png';
import skg3image from '../../../../images/skg3.png';
import LockToolFooter from './footer';
import QuestionOption from './question-option';
import StarsOnLockModal from './modals/stars-on-lock-modal';
import { LockToolContext } from '../../context/lockToolContext';
import pushToDataLayer from '../../core-helpers/gtm';

const LockToolQuestionStarsOnLock = ({ history }) => {
    const lockToolFormController = useContext(LockToolContext);

    const { register, handleSubmit, watch } = useForm();
    const [modalOpen, setModalOpen] = useState(false);
    const watchstarsOnLock = watch('starsOnLock');

    useEffect(() => {
        if (lockToolFormController.cylinderInstallationType === null) {
            history.push('./');
        }
    }, []);

    const onSubmit = (data) => {
        lockToolFormController.setStarsOnLock(data.starsOnLock);
        history.push('./stap3');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="locktool">
            <div className="locktool__content">
                <div className="container">
                    <div className="locktool__header">
                        <div className="locktool__meta">
                            <div className="locktool__count">2/5</div>
                        </div>
                        <h1 className="locktool__title">
                            Hoeveel SKG sterren staan er op het slot?
                        </h1>
                        <div className="locktool__more-info">
                            Het aantal SKG sterren vind je meestal aan de
                            zijkant van de deur. Op de plek waar het slot in de
                            deur gaat.{' '}
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    pushToDataLayer({
                                        event: 'LockToolAction',
                                        lockToolAction:
                                            'open modal stars on lock',
                                    });
                                    setModalOpen(true);
                                }}
                            >
                                Ik heb meer hulp nodig
                            </a>
                        </div>
                    </div>

                    <div className="question-group">
                        <QuestionOption
                            name="starsOnLock"
                            value="0"
                            title="Geen ster"
                            register={register}
                        />
                        <QuestionOption
                            name="starsOnLock"
                            value="1"
                            title="1 ster"
                            img={skg1image}
                            register={register}
                        />
                        <QuestionOption
                            name="starsOnLock"
                            value="2"
                            title="2 sterren"
                            img={skg2image}
                            register={register}
                        />
                        <QuestionOption
                            name="starsOnLock"
                            value="3"
                            title="3 sterren"
                            img={skg3image}
                            register={register}
                        />
                    </div>
                </div>
            </div>
            {modalOpen && (
                <StarsOnLockModal onClose={() => setModalOpen(false)} />
            )}
            <LockToolFooter>
                <Link to="./stap1">Vorige</Link>
                <input
                    className="button"
                    type="submit"
                    value="Volgende"
                    disabled={!watchstarsOnLock}
                />
            </LockToolFooter>
        </form>
    );
};

LockToolQuestionStarsOnLock.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(LockToolQuestionStarsOnLock);
