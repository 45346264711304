import React from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';

// Components
import Footer from '../components/footer';
import PredictCTA from '../components/predict-cta';
import PropTypes from 'prop-types';
import {BAD_REQUEST as HTTP_BAD_REQUEST, NOT_FOUND as HTTP_NOT_FOUND, OK as HTTP_OK} from 'http-status-codes';
import pushToDataLayer from '../core-helpers/gtm';
import PreventionTip from "../components/prevention-tip";

// Redux
import { getStore } from '../store';
import classnames from 'classnames';
import Helmet from 'react-helmet';

const priceOptions = [
    { value: 0, label: 'geen geld' },
    { value: 1, label: 'wel geld' },
];

const propertyTypeOptions = [
    { value: 'Hoekwoning', label: 'hoekwoning' },
    { value: 'Twee-onder-een-kapwoning', label: 'twee-onder-een-kapwoning' },
    { value: 'Tussenwoning', label: 'tussenwoning' },
    { value: 'Vrijstaand', label: 'vrijstaande woning' },
    { value: 'Appartement', label: 'appartement' }
];

const effortOptions = [
    { value: 1, label: 'geen' },
    { value: 2, label: 'een beetje' },
    { value: 3, label: 'redelijk wat' },
    { value: 4, label: 'veel' }
];

class PreventionTipOverviewView extends React.Component {

    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.object.isRequired
        })
    };

    constructor(props) {
        super(props);

        this.state = {
            preventionTips: [],
            filteredPreventionTips: [],
            propertyTypeFilter: null,
            priceFilter: null,
            effortFilter: null,
            isFetching: false
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentDidMount() {
        this.fetchPreventionTips();
    }

    pushActionToDataLayer = (action, filterType, filterValue) => {
        pushToDataLayer({
            event: 'BurglaryToolAction',
            burglaryAction: action,
            filterType: filterType,
            filterValue: filterValue
        });
    };

    fetchPreventionTips = async() => {
        this.setState({
            isFetching: true
        });

        const { BASENAME } = getStore();
        const response = await fetch(`${BASENAME}/api/preventietips`);

        this.setState({
            isFetching: false
        });

        switch ( response.status ) {
            case HTTP_OK: {
                const json = await response.json();

                return this.setPreventionTips(json);
            }
            case HTTP_BAD_REQUEST: {
                const json = await response.json();

                alert(json.error); // eslint-disable-line no-alert
                window.location.href = BASENAME;

                return;
            }
            default: {
                return alert('Er ging iets mis tijdens het ophalen van de data, probeer het later nog eens'); // eslint-disable-line no-alert
            }
        }
    };

    setPreventionTips = (preventionTips) => {
        this.setState({
            preventionTips
        }, () => this.filterPreventionTips(preventionTips));
    };

    render() {
        const { filteredPreventionTips } = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Preventietips - Inbraakbarometer</title>
                    <meta property="og:title" content="Preventietips - Inbraakbarometer" />
                    <meta name="description" content="De kans op inbraak bij jouw huis verkleinen? Ga direct aan de slag met simpele en effectieve preventietips." />
                    <meta property="og:description" content="De kans op inbraak bij jouw huis verkleinen? Ga direct aan de slag met simpele en effectieve preventietips." />
                </Helmet>
                <div className="wrapper">
                    {this.renderLoader()}
                    {this.renderFilter()}
                    {this.renderPreventionTips(filteredPreventionTips)}
                    <PredictCTA />
                    <Footer flatten/>
                </div>
            </React.Fragment>
        );
    }

    renderLoader() {
        const { isFetching } = this.state;
        const classes = classnames('loader-container', {
            'is-loading': isFetching
        });

        return (
            <div className={classes}>
                <div className="loader loader--large"></div>
            </div>
        );
    }

    filterPreventionTips(preventionTips){
        const { priceFilter, effortFilter, propertyTypeFilter } = this.state;

        const filteredPreventionTips = preventionTips.filter(
            (preventionTip) => {
                return (priceFilter === null ? true : ((priceFilter === 0) ? preventionTip.costsMoney === false : preventionTip.costsMoney === true))
                && (effortFilter === null ? true : (preventionTip.effort <= effortFilter))
                && (propertyTypeFilter === null ? true : (preventionTip.propertyTypes.includes(propertyTypeFilter)));
            }
        );

        this.setState({
            filteredPreventionTips
        });
    }

    handleFilterChange = name => value => {
        this.pushActionToDataLayer('SelectedPreventieTipFilter', name, value.value);
        this.setState({
            [name]: value.value,
        }, () => this.filterPreventionTips(this.state.preventionTips));
    };

    renderFilter() {
        return (
            <section className="section">
                <div className="container">
                    <p className="filters-intro">Hulp nodig bij het vinden van een preventietip die bij jouw huis past? Vertel ons iets meer over jouw situatie.</p>
                    <div className="filters">
                        <span className="filters__text">Ik woon in een</span>
                        <Select options={propertyTypeOptions}
                                onChange={this.handleFilterChange('propertyTypeFilter')}
                                className="filters-select"
                                classNamePrefix="filters-select"
                                placeholder="selecteer"
                                isSearchable={false}
                        />
                        {/* TEMPORARY DISABLED effortfilter */}
                        {/*<span className="filters__text">en wil</span>*/}
                        {/*<Select options={effortOptions}*/}
                        {/*        onChange={this.handleFilterChange('effortFilter')}*/}
                        {/*        className="filters-select"*/}
                        {/*        classNamePrefix="filters-select"*/}
                        {/*        placeholder="selecteer"*/}
                        {/*        isSearchable={false}*/}

                        {/*/>*/}
                        {/*<span className="filters__text">inspanning verrichten.</span>*/}
                        <span className="filters__text">en wil op dit moment</span>
                        <Select options={priceOptions}
                                onChange={this.handleFilterChange('priceFilter')}
                                className="filters-select"
                                classNamePrefix="filters-select"
                                placeholder="selecteer"
                                isSearchable={false}
                        />
                        <span className="filters__text">uitgeven aan een veiliger huis.</span>
                    </div>
                </div>
            </section>
        );
    }

    renderPreventionTips(preventionTips) {
        const { priceFilter, effortFilter, propertyTypeFilter } = this.state;

        return (
            <section className="section">
                <div className="container">
                    <div className="prevention-tip-card-holder">
                        {preventionTips.length > 0 ?
                           preventionTips.map((preventionTip) => (
                                <PreventionTip key={`id:${preventionTip.id}`} preventionTip={preventionTip} priceFilter={priceFilter} effortFilter={effortFilter} propertyTypeFilter={propertyTypeFilter} />
                           ))
                           : <p>Er zijn geen preventietips voor jouw zoekopdracht gevonden.</p>
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default connect()(PreventionTipOverviewView);
